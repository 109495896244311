import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CButton from "../../../../../components/common/CButton";
import { Theme } from "../../../../../Theme";
import ISICMappingCard from "../ui/ISICMappingCard";
import { theme } from "antd";
import ISCOClassificationCard from "../ui/ISCOClassificationCard";
import { RootState } from "../../../../../store/store";
import { useSelector } from "react-redux";

interface IsicMappingProps {
  data: any;
  handleSubmitForm: (form: any) => void;
  handleDraftForm: (form: any) => void;
  handleStepBack: () => void;
  initiatives: any;
  alignValue: any;
  selectedKey: any;
  submissionData: any;
}

const ISCOClassificationForm: React.FC<IsicMappingProps> = ({
  data,
  handleSubmitForm,
  handleDraftForm,
  handleStepBack,
  initiatives,
  alignValue,
  selectedKey,
  submissionData
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(data || { data: [] });
  const [openCardIndex, setOpenCardIndex] = useState<number | null>(0);
  const entityType = useSelector((state: RootState) => state?.submission?.type);

  const handleCardToggle = (index: number) => {
    setOpenCardIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const getTotalJobsSum = () => {
    if (initiatives) {
      const initiativeIndex = initiatives.findIndex(
        (item: any) => item.value === alignValue
      );
      const totalJobs =
        initiatives[initiativeIndex]?.data[1]?.tabs[0]?.sections[0]
          ?.total_number_jobs?.value;
      return totalJobs;
    } 
    if(submissionData?.[entityType]?.forms) {
      const value = submissionData?.[entityType]?.forms[1]?.tabs[0]?.sections[0]?.total_number_jobs?.value;
      if (value) {
        return value;
      }
    }
  };

  const calculateTotalJobs = () => {
    return formData.data.reduce((total: number, form: any) => {
      const numOfJobs = form.noOfJobs?.value
        ? parseInt(form.noOfJobs.value)
        : 0;
      return total + numOfJobs;
    }, 0);
  };

  const handleAddNew = () => {
    const newFormData = formData.data.map((form: any) => {
      const newForm = { ...form };
      if (newForm.levels) {
        newForm.levels = newForm.levels.map((level: any) => ({
          ...level,
          value: "",
        }));
      }
      if (newForm.noOfJobs) {
        newForm.noOfJobs = { ...newForm.noOfJobs, value: "" };
      }
      return newForm;
    });
    setFormData((prevFormData: any) => {
      const newData = [...prevFormData.data, newFormData[0]];
      setOpenCardIndex(newData.length - 1);
      return { ...prevFormData, data: newData };
    });
  };

  const handleInputChange = (value: string, index: number) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      data: prevFormData.data.map((form: any, i: number) => {
        if (i === index) {
          return {
            ...form,
            noOfJobs: {
              ...form.noOfJobs,
              value,
            },
          };
        }
        return form;
      }),
    }));
  };

  const handleLevelChange = (
    value: string,
    index: number,
    recordIndex: number,
    dropdown: any
  ) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      data: prevFormData.data.map((form: any, i: number) => {
        if (i === recordIndex) {
          return {
            ...form,
            groups: form.groups.map((level: any, levelIndex: number) => {
              if (levelIndex === index) {
                return {
                  ...level,
                  value,
                  options: dropdown?.options,
                };
              }
              return level;
            }),
          };
        }
        return form;
      }),
    }));
  };

  const removeISICHandler = (index: number) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      data: prevFormData.data.filter((_: any, i: number) => i !== index),
    }));
  };

  return (
    <>
      <div className="w-100" style={{ position: "relative" }}>
        {data?.hasError && (
          <p className="sf-bold" style={{ color: Theme?.colors?.red }}>
            {data?.errorMsg}
          </p>
        )}
         <div
          className="d-flex justify-content-end"
          style={{ position: "absolute", top: "-90px", right: "0px" }}
        >
          <div className="mx-2">
            <span className="total-remaining-job-types">{t("Total Jobs")}</span>
            <p className="total-remaining-jobs-no">
              {getTotalJobsSum() ? getTotalJobsSum()?.toLocaleString() : 0}
            </p>
          </div>
          <div
            className="add-initiative-div px-2"
            style={{ height: "50px" }}
          >
            <span className="total-remaining-job-types">{t("Remaining Jobs")}</span>
            <p className="total-remaining-jobs-no" style={{ color: "#DC2626" }}>
              {(getTotalJobsSum() - calculateTotalJobs()  >= 0 ?  getTotalJobsSum() - calculateTotalJobs() : 0 )?.toLocaleString()}
            </p>
          </div>
        </div>
        {formData?.data?.map((item: any, index: number) => {
          return (
            <ISCOClassificationCard
              data={item}
              recordIndex={index}
              open={openCardIndex === index}
              onToggle={() => handleCardToggle(index)}
              handleInputChange={handleInputChange}
              handleLevelChange={handleLevelChange}
              removeISICHandler={removeISICHandler}
              totalLength={formData?.data?.length}
            />
          );
        })}
        <div className="mt-2 d-flex justify-content-end">
          <CButton className="add-new-year" onClick={() => handleAddNew()}>
            <img src={Theme.icons.icn_plus} />
            <span>{t("Add New")}</span>
          </CButton>
        </div>

        <div className="form-bottom-div d-flex justify-content-between">
        <CButton className="floppy-btn"  
            onClick={() => handleDraftForm(formData)}>
          <img src={Theme.icons.icn_floppy_disk} />
          {t("Save & Continue Later")}
        </CButton>
        <div className="form-footer-div">
          <CButton
            className="save-continue-btn w-75 mx-2"
            onClick={() => handleStepBack()}
          >
            <img src={Theme.icons.icn_arrow_left_blue} />
            {t("Back")}
          </CButton>
          <CButton
            className="next-btn"
            onClick={() => handleSubmitForm(formData)}
          >
            {t("Next Step")}
            <img src={Theme.icons.icn_arrow_right_white} />
          </CButton>
        </div>
      </div>
      </div>
    </>
  );
};

export default ISCOClassificationForm;
