import icn_close_sider from "../assets/icons/icn_close_sider.svg";
import icn_notification_bell from "../assets/icons/icn_notification_bell.svg";
import icn_switch_languages from "../assets/icons/icn_switch_languages.svg";
import icn_upload from "../assets/icons/icn_upload.svg";
import icn_log_out from "../assets/icons/icn_log_out.svg";
import icn_search from "../assets/icons/icn_search.svg";
import icn_customer_service_line from "../assets/icons/icn_customer_service_line.svg";
import icn_settings from "../assets/icons/icn_settings.svg";
import icn_user from "../assets/icons/icn_user.svg";
import icn_action from "../assets/icons/icn_action.svg";
import icn_add_user_plus from "../assets/icons/icn_add_user_plus.svg";
import icn_home from "../assets/icons/icn_home.svg";
import icn_home_black from "../assets/icons/icn_home_black.svg";
import icn_strategies from "../assets/icons/icn_strategies.svg";
import icn_trending from "../assets/icons/icn_trending.svg";
import icn_message_square from "../assets/icons/icn_message_square.svg";
import icn_message_square_black from "../assets/icons/icn_message_square_black.svg";
import icn_plus from "../assets/icons/icn_plus.svg";
import icn_upload_evidence from "../assets/icons/icn_upload_evidence.svg";
import icn_check_square from "../assets/icons/icn_check_square.svg";
import icn_cross from "../assets/icons/icn_cross.svg";
import icn_chevron_down from "../assets/icons/icn_chevron_down.svg";
import icn_entity_user from "../assets/icons/icn_entity_user.svg";
import icn_delete from "../assets/icons/icn_delete.svg";
import icn_blue_upload from "../assets/icons/icn_blue_upload.svg";
import icn_grey_search from "../assets/icons/icn_grey_search.svg";
import icn_caution from "../assets/icons/icn_caution.svg";
import icn_arrow_left_blue from "../assets/icons/icn_arrow_left_blue.svg";
import icn_arrow_right_white from "../assets/icons/icn_arrow_right_white.svg";
import icn_floppy_disk from "../assets/icons/icn_floppy_disk.svg";
import icn_dropdown_red from "../assets/icons/icn_dropdown_red.svg";
import icn_warning_red from "../assets/icons/icn_warning_red.svg";

export const Theme = {
  colors: {
    primary: "#181F54",
    secondary: "#3544B6",
    secondary_spoc: "#2BB1BB",
    blue: "#007BFF",
    gray: "#969696",
    gray1: "#A1A1A1",
    success: "#09C97F",
    error: "#F95668",
    white: "#FFFFFF",
    black: "#000000",
    red: "#DB1F35",
    yellow: "#F5C004",
    redShade: "#FF4444",
    orange: "#EC994C",
    themeSecondary:'#2BB1BB'
  },
  icons: {
    icn_close_sider,
    icn_notification_bell,
    icn_switch_languages,
    icn_upload,
    icn_log_out,
    icn_customer_service_line,
    icn_settings,
    icn_user,
    icn_search,
    icn_action,
    icn_add_user_plus,
    icn_home,
    icn_home_black,
    icn_strategies,
    icn_trending,
    icn_message_square_black,
    icn_message_square,
    icn_plus,
    icn_upload_evidence,
    icn_check_square,
    icn_cross,
    icn_chevron_down,
    icn_entity_user,
    icn_delete,
    icn_blue_upload,
    icn_grey_search,
    icn_caution,
    icn_arrow_left_blue,
    icn_arrow_right_white,
    icn_floppy_disk,
    icn_dropdown_red,
    icn_warning_red
  },
  images: {
    img_national_labour: require("../assets/images/img_national_labour.png"),
    img_placeholder: require("../assets/images/img_placeholder.png"),

    img_ad_one: require("../assets/images/img_ad_one.png"),
    img_ad_two: require("../assets/images/img_ad_two.png"),
    img_ad_three: require("../assets/images/img_ad_three.png"),
    img_ad_four: require("../assets/images/img_ad_four.png"),
    img_ad_five: require("../assets/images/img_ad_five.png"),
    img_ad_six: require("../assets/images/img_ad_six.png"),
    img_ad_seven: require("../assets/images/img_ad_seven.png"),
    img_registration_done: require("../assets/images/img_registration_done.png"),
  },
  // fonts: {
  //   Light: 'Nunito-Light',
  //   Regular: 'Nunito-Regular',
  //   Medium: 'Nunito-Medium',
  //   SemiBold: 'Nunito-SemiBold',
  //   Bold: 'Nunito-Bold',
  // },
};
