import React, { useState } from "react";
import { Select } from "antd";
import CInput from "./CInput";
import { Theme } from "../../Theme";

const { Option } = Select;

interface CustomSelectProps {
  options: { value: string; label: string }[];
  placeholder?: string;
  className?: string;
  value?: string;
  onChange?: (value: string) => void;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  showSearch?: boolean;
  onSearch?: (searchText: string) => void;
  hideValue?: boolean;
  reverseOptions?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  label,
  className,
  placeholder,
  value,
  onChange,
  disabled,
  required,
  onSearch,
  showSearch,
  hideValue,
  reverseOptions
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const inputClassName = `dropdown ${className || ""}`;

  const filteredOptions = options.filter((option) =>
    option.label?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
  option.value?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  const handleSelectChange = (value: string) => {
    if (onChange) {
      onChange(value); // Call the parent onChange handler
    }
    setSearchQuery(""); // Clear the search query when an option is selected
  };

  return (
    <div>
      {label && (
        <label
          className="inputfield-label"
          style={{ display: "block", marginBottom: "5px" }}
        >
          {label}
          {required && <span style={{ color: "red" }}>*</span>}
        </label>
      )}
      <Select
        placeholder={placeholder || "Please select"}
        value={value}
        onChange={handleSelectChange}
        style={{ width: "100%" }}
        className={inputClassName}
        disabled={disabled}
        // showSearch={showSearch}
        // onSearch={onSearch}
        // filterOption={false}
        dropdownRender={(menu) => (
          <div>
            {showSearch && (
              <CInput
                prefix={
                  <img
                    src={Theme.icons.icn_search}
                    alt="search"
                    style={{ marginRight: 5, height: "20px" }}
                  />
                }
                placeholder="Search mapping value by name or code"
                value={searchQuery}
                className="mx-3 mt-1"
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                  marginBottom: 8,
                  padding: "6px 10px",
                  height: "35px",
                  width: "95%",
                  backgroundColor: "#F8F9FA",
                  borderStyle: "none",
                }}
              />
            )}
            {menu}
          </div>
        )}
      >
        {filteredOptions.map((option) => (
          <Option key={option.value} value={option.value}>
            {showSearch && !hideValue ? (
              <span>
                { reverseOptions ? (`${option.value} | ${option.label}`).split(' | ').reverse().join(' | ') : (`${option.value} | ${option.label}`)}
              </span>
            ) : (
              option.label
            )}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default CustomSelect;
