import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DataSubmissionData {
  [key: string]: {
    forms?: {
      name: string;
      heading: string;
      subheading: string;
      data: any[];
    }[];
    initiatives?: any;
    subSector?: any;
    configuration?: any;
  };
}

interface UpdateDropdownPayload {
    index: number;
    value: any; 
  }

interface AuthState {
  data: DataSubmissionData;
  type: string;
  configuration?: any;
  initiativeCopy?: any;
  nextFormName: string | null;
  uploadData: { [key: string]: File | null };
  isSubmit: Boolean;
  uploadInitiativeData: any;
  levelsDropdownData: any;
  submissionStatus: string;
}

const initialState: AuthState = {
  data: {},
  type: "",
  configuration: {},
  initiativeCopy: [],
  nextFormName: null,
  uploadData: {},
  isSubmit: false,
  uploadInitiativeData: {},
  levelsDropdownData: [[], [], [], [], [], []],
  submissionStatus: ''
};

const dataSubmissionSlice = createSlice({
  name: "dataSubmission",
  initialState,
  reducers: {
    addData: (
      state,
      action: PayloadAction<{
        data: DataSubmissionData;
        type: string;
        configuration: any;
        initiativeCopy: any;
      }>
    ) => {
      state.data = action.payload.data;
      state.type = action.payload.type;
      state.configuration = action.payload.configuration;
      state.initiativeCopy = action.payload.initiativeCopy;
    },
    updateFormData: (
      state,
      action: PayloadAction<{
        key: string;
        name: string;
        newForm: {
          name: string;
          heading: string;
          subheading: string;
          data: any[];
        };
      }>
    ) => {
      const { key, name, newForm } = action.payload;

      // Check if the strategy exists
      if (state.data[key]?.forms) {
        const forms = state.data[key].forms!;

        // Find the index of the form to replace
        const formIndex = forms.findIndex((form) => form.name === name);

        if (formIndex !== -1) {
          // Replace the form at the specific index
          forms[formIndex] = { ...newForm };
        }
      }
    },
    // updateFormData: (
    //   state,
    //   action: PayloadAction<{
    //     key: string;
    //     name: string;
    //     newForm: {
    //       name: string;
    //       heading: string;
    //       subheading: string;
    //       data: any;
    //     };
    //   }>
    // ) => {
    //   const { key, name, newForm } = action.payload;
    
    //   // Check if the strategy exists
    //   if (state.data[key]?.forms) {
    //     const forms = state.data[key].forms!;
    
    //     if (name === 'stg_configuration_information') {
    //       // Check if the form already exists
    //       const formIndex = forms.findIndex((form) => form.name === name);
    
    //       if (formIndex !== -1) {
    //         // If the form exists, replace it
    //         forms[formIndex] = { ...newForm };
    //       } else {
    //         // If the form doesn't exist, add it at the first index
    //         forms.unshift(newForm); // Adds to the beginning of the array
    //       }
    //     } else {
    //       // If the form is not 'configuration', replace the existing form
    //       const formIndex = forms.findIndex((form) => form.name === name);
    
    //       if (formIndex !== -1) {
    //         // Replace the form at the specific index
    //         forms[formIndex] = { ...newForm };
    //       }
    //     }
    //   }
    // },
    
    updateInitiativeFormData: (
      state,
      action: PayloadAction<{
        initiative_name: string;
        name: string;
        newForm: {
          name: string;
          heading: string;
          subheading: string;
          data: any[];
        };
      }>
    ) => {
      const { initiative_name, name, newForm } = action.payload;
      const existingFormIndex = state?.data?.vrps?.initiatives.findIndex(
        (initiative: any) => initiative.name === initiative_name
      );

      // // Check if the strategy exists
      if (existingFormIndex > -1) {
        const forms = state?.data?.vrps?.initiatives[existingFormIndex]?.data!;

        // Find the index of the form to replace
        const formIndex = forms.findIndex((form: any) => form.name === name);

        if (formIndex !== -1) {
          // Replace the form at the specific index
          forms[formIndex] = { ...newForm };
        }
      }
    },
    updateSubSectorFormData: (
      state,
      action: PayloadAction<{
        key: string;
        sector_value: string;
        name: string;
        newForm: {
          name: string;
          heading: string;
          subheading: string;
          data: any[];
        };
      }>
    ) => {
      const { key, sector_value, name, newForm } = action.payload;
      const existingFormIndex = state?.data?.[key]?.subSector.findIndex(
        (sector: any) => sector.value === sector_value
      );
      // // Check if the strategy exists
      if (existingFormIndex > -1) {
        const forms = state?.data?.[key]?.subSector[existingFormIndex]?.data!;

        // Find the index of the form to replace
        const formIndex = forms.findIndex((form: any) => form.name === name);

        if (formIndex !== -1) {
          // Replace the form at the specific index
          forms[formIndex] = { ...newForm };
        }
      }
    },
    getNextFormName: (
      state,
      action: PayloadAction<{ key: string; currentIndex: number }>
    ) => {
      const { key, currentIndex } = action.payload;
      const forms = state.data?.[key]?.forms;

      if (forms && currentIndex + 1 < forms.length) {
        state.nextFormName = forms[currentIndex + 1].name; // Store next form name in state
      } else {
        state.nextFormName = null; // Reset if no next form exists
      }
    },
    getPreviousFormName: (
        state,
        action: PayloadAction<{ key: string; currentIndex: number }>
      ) => {
        const { key, currentIndex } = action.payload;
        const forms = state.data?.[key]?.forms;
        if(currentIndex === 0){
          state.nextFormName = "stg_configuration_information";
         
        } else if (forms && currentIndex + 1 <= forms.length) {
            state.nextFormName = forms[currentIndex - 1].name;
        }else {
          state.nextFormName = null; // Reset if no previous form exists
        }
      },
    getNextInitiativeFormName: (
      state,
      action: PayloadAction<{ initiative_name: string; currentIndex: number }>
    ) => {
      const { initiative_name, currentIndex } = action.payload;

      const existingFormIndex = state?.data?.vrps?.initiatives.findIndex(
        (initiative: any) => initiative.name === initiative_name
      );

      if (existingFormIndex > -1) {
        const forms = state?.data?.vrps?.initiatives[existingFormIndex]?.data!;
        if (forms && currentIndex + 1 < forms.length) {
          state.nextFormName = forms[currentIndex + 1].name; // Store next form name in state
        } else {
          state.nextFormName = null; // Reset if no next form exists
        }
      }
    },
    getNextSubSectorFormName: (
      state,
      action: PayloadAction<{ key: string; sector_value: string; currentIndex: number }>
    ) => {
      const { key, sector_value, currentIndex } = action.payload;
      const existingFormIndex = state?.data?.[key]?.subSector.findIndex(
        (sector: any) => sector.value === sector_value
      );

      if (existingFormIndex > -1) {
        const forms = state?.data?.[key]?.subSector[existingFormIndex]?.data!;
        if (forms && currentIndex + 1 < forms.length) {
          state.nextFormName = forms[currentIndex + 1].name; // Store next form name in state
        } else {
          state.nextFormName = null; // Reset if no next form exists
        }
      }
    },
    getPreviousSubSectorFormName: (
      state,
      action: PayloadAction<{  key:string; sector_value: string; currentIndex: number }>
    ) => {
      const { key, sector_value, currentIndex } = action.payload;

      const existingFormIndex = state?.data?.[key]?.subSector.findIndex(
        (sector: any) => sector.value === sector_value
      );

      if (existingFormIndex > -1) {
        if(currentIndex === 0){
          state.nextFormName = "stg_configuration_information";
        }else{
          const forms = state?.data?.[key]?.subSector[existingFormIndex]?.data!;
          if (forms && currentIndex - 1 >= 0) {
            state.nextFormName = forms[currentIndex - 1].name; // Store previous form name in state
          } else {
            state.nextFormName = null; // Reset if no previous form exists
          }
        }
      }
    },
    getPreviousInitiativeFormName: (
        state,
        action: PayloadAction<{ initiative_name: string; currentIndex: number }>
      ) => {
        const { initiative_name, currentIndex } = action.payload;
  
        const existingFormIndex = state?.data?.vrps?.initiatives.findIndex(
          (initiative: any) => initiative.name === initiative_name
        );
  
        if (existingFormIndex > -1) {
          const forms = state?.data?.vrps?.initiatives[existingFormIndex]?.data!;
          if (forms && currentIndex - 1 >= 0) {
            state.nextFormName = forms[currentIndex - 1].name; // Store previous form name in state
          } else {
            state.nextFormName = null; // Reset if no previous form exists
          }
        }
      },
    updateUploadData: (
      state,
      action: PayloadAction<{ type: string; file: File | null }>
    ) => {
      const { type, file } = action.payload;
      state.uploadData[type] = file;
    },

    updateInitiativeUploadData: (
        state,
        action: PayloadAction<{ initiative: string; key: string; file: File | null }>
    ) => {

        const { initiative, key, file } = action.payload;
        if (!state.uploadInitiativeData[initiative]) {
            state.uploadInitiativeData[initiative] = {
              init_attendance_proof_file: null,
              init_methodology_file: null,
              init_strategy_file: null,
              init_other_documents_file: null,
            };
        }
    
        // Update the specific key with the file
        if (state.uploadInitiativeData[initiative].hasOwnProperty(key)) {
            state.uploadInitiativeData[initiative][key] = file;
        } else {
            console.error(`Invalid key: ${key} for initiative ${initiative}`);
        }
    },

    updateSubSectorUploadData: (
      state,
      action: PayloadAction<{ initiative: string; key: string; file: File | null }>
  ) => {
      const { initiative, key, file } = action.payload;
  
      // Initialize the initiative data if it doesn't exist yet
      if (!state.uploadInitiativeData[initiative]) {
          state.uploadInitiativeData[initiative] = {};
      }
  
      // Check if the key already exists. If not, initialize it with null.
      if (!(key in state.uploadInitiativeData[initiative])) {
          state.uploadInitiativeData[initiative][key] = null;
      }
  
      // Update or add the key with the provided file
      state.uploadInitiativeData[initiative][key] = file;
  },
    
    formSubmissionType: (state, action: PayloadAction<{ type: boolean }>) => {
      const { type } = action.payload;
      state.isSubmit = type;
    },
    clearState: (state) => {
        Object.assign(state, initialState); // Reset state to initialState
      },
    //   setLevelDropdownData: (state, action: PayloadAction<any[]>) => {
    //     state.levelsDropdownData = action.payload;
    //   },
      setLevelDropdownData: (state, action: PayloadAction<UpdateDropdownPayload>) => {
        const {value, index } = action.payload;
  
        // Check if the index is valid
        if (index >= 0 && index < state.levelsDropdownData.length) {
          state.levelsDropdownData[index] = value;
        } else {
          console.warn(`Invalid index: ${index}`);
        }
      },

      formSubmissionStatus: (state, action: PayloadAction<{ status: string }>) => {
        const { status } = action.payload;
        state.submissionStatus = status;
      },
      removeSubSector: (state, action) => {
        const indexToRemove = action.payload;
        if ( state.data.strategy.subSector.length !== 0 && indexToRemove >= 0 && indexToRemove < state.data.strategy.subSector.length) {
          state.data.strategy.subSector.splice(indexToRemove, 1);
        }
      },
      setNextFormName: (state, action) => {
        state.nextFormName = action.payload; // Update nextFormName in state
      },
  },
});

export const {
  addData,
  updateFormData,
  updateInitiativeFormData,
  getNextFormName,
  getPreviousFormName,
  getNextInitiativeFormName,
  getPreviousInitiativeFormName,
  updateUploadData,
  updateInitiativeUploadData,
  formSubmissionType,
  clearState,
  setLevelDropdownData,
  formSubmissionStatus,
  removeSubSector,
  getNextSubSectorFormName,
  updateSubSectorFormData, 
  getPreviousSubSectorFormName,
  updateSubSectorUploadData,
  setNextFormName
} = dataSubmissionSlice.actions;
export default dataSubmissionSlice.reducer;
