import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Segmented } from "antd";
import CButton from "../../../../../components/common/CButton";
import JobPhase from "../ui/JobPhase";
import { error } from "console";
import { Theme } from "../../../../../Theme";

interface TabOption {
  name: string;
  heading: string;
  sections: any;
}

interface JobCreationProps {
  data: any;
  handleSubmitForm: (form: any) => void;
  handleDraftForm: (form: any) => void;
  handleStepBack: () => void;
  setAlert: React.Dispatch<
    React.SetStateAction<{ message: string; type: string; visible: boolean }>
  >;
}
const JobCreationForm: React.FC<JobCreationProps> = ({
  data,
  handleSubmitForm,
  handleDraftForm,
  handleStepBack,
  setAlert,
}) => {
  const [formData, setFormData] = useState(data);
  const [alignValue, setAlignValue] = React.useState<string>();
  const { t } = useTranslation();

  const handleChange = (
    value: string,
    name: string,
    year: string,
    sectionName: string,
    tabName: string
  ) => {
    let noOfJobsSum = 0;
    const numericValue = Number(value);
    const fieldName = name.split("_").slice(1).join("_");
    const tabValue = tabName.split("_").slice(1).join("_");
    const isNoOfJobs = name?.includes("no_of_jobs");
    const checkJobskey = isNoOfJobs ? "noOfJobs" : "nationalJobs";
    const totalKey = isNoOfJobs ? "total_number_jobs" : "total_national_jobs";

    let updatedFormData = formData?.tabs?.map((tab: any) => {
      if (tab.name === tabName) {
        return {
          ...tab,
          sections: tab.sections.map((section: any) => {
            if (section.name === sectionName) {
              let val = {
                ...section,
                data: section.data.map((dataItem: any) => {
                  if (dataItem.year === year && section.name === sectionName) {
                    noOfJobsSum += numericValue;
                  } else if (
                    dataItem?.[checkJobskey]?.value &&
                    parseInt(dataItem?.[checkJobskey]?.value) > 0
                  ) {
                    noOfJobsSum += parseInt(dataItem?.[checkJobskey]?.value);
                  }
                  if (dataItem.year === year && section.name === sectionName) {
                    if (tabValue === "direct_jobs") {
                      if (
                        fieldName === "national_jobs" &&
                        Number(dataItem?.noOfJobs?.value) < numericValue
                      ) {
                        return {
                          ...dataItem,
                          [name === "stg_no_of_jobs" ||
                          name === "giga_no_of_jobs" ||
                          name === "init_no_of_jobs"
                            ? "noOfJobs"
                            : "nationalJobs"]: {
                            ...dataItem[
                              name === "stg_no_of_jobs" ||
                              name === "giga_no_of_jobs" ||
                              name === "init_no_of_jobs"
                                ? "noOfJobs"
                                : "nationalJobs"
                            ],
                            hasError: true,
                            errorMsg:
                              "No of national jobs should be less than No of Jobs",
                            value,
                          },
                        };
                      } else {
                        return {
                          ...dataItem,
                          [name === "stg_no_of_jobs" ||
                          name === "giga_no_of_jobs" ||
                          name === "init_no_of_jobs"
                            ? "noOfJobs"
                            : "nationalJobs"]: {
                            ...dataItem[
                              name === "stg_no_of_jobs" ||
                              name === "giga_no_of_jobs" ||
                              name === "init_no_of_jobs"
                                ? "noOfJobs"
                                : "nationalJobs"
                            ],
                            hasError: false,
                            errorMsg: "",
                            value,
                          },
                        };
                      }
                    }
                    return {
                      ...dataItem,
                      [name === "stg_no_of_jobs" ||
                      name === "giga_no_of_jobs" ||
                      name === "init_no_of_jobs"
                        ? "noOfJobs"
                        : "nationalJobs"]: {
                        ...dataItem[
                          name === "stg_no_of_jobs" ||
                          name === "giga_no_of_jobs" ||
                          name === "init_no_of_jobs"
                            ? "noOfJobs"
                            : "nationalJobs"
                        ],
                        value: value,
                      },
                    };
                  }
                  return dataItem;
                }),
                [totalKey]: { ...section[totalKey], value: noOfJobsSum },
              };
              return val;
            }
            return section;
          }),
        };
      }
      return tab;
    });

    setFormData((prevState: any) => ({
      ...prevState,
      tabs: updatedFormData,
    }));
  };

  const handleTotalChange = (
    value: any,
    fieldName: any,
    sectionName: string,
    tabName: string
  ) => {
    const updatedFormData = formData?.tabs?.map((tab: any) => {
      if (tab.name === tabName) {
        return {
          ...tab,
          sections: tab.sections.map((section: any) => {
            if (section.name === sectionName) {
              const updatedSection = {
                ...section,
                //   return {
                //     ...dataItem,
                //     [fieldName === 'total_number_jobs'
                //       ? "noOfJobs"
                //       : "nationalJobs"]: {
                //       ...dataItem[fieldName === 'total_number_jobs'
                //           ? "noOfJobs"
                //           : "nationalJobs"
                //       ],
                //       value: '',
                //     },
                // }),
                data: section.data.map((dataItem: any) => {
                  return {
                    ...dataItem,
                    [fieldName === 'total_number_jobs'
                      ? "noOfJobs"
                      : "nationalJobs"]: {
                      ...dataItem[fieldName === 'total_number_jobs'
                        ? "noOfJobs"
                        : "nationalJobs"
                      ],
                      hasError: false,
                      value: '',
                    },
                  };
                }),
                [fieldName]: {
                  ...section[fieldName], // Preserve other properties of the field
                  value, // Update the value with the new input value
                },
              };
              return updatedSection;
            }
            return section;
          }),
        };
      }
      return tab;
    });

    setFormData((prevState: any) => ({
      ...prevState,
      tabs: updatedFormData,
    }));
  };

  useEffect(() => {
    if (data?.tabs[0]?.sections[0]?.data?.length < 10) {
      const updatedTabs = data?.tabs?.map((tab: any) => {
        const updatedSections = tab.sections.map((section: any) => {
          const updatedData = Array.from({ length: 11 }, (_, index) => {
            const year = (parseInt(section.data[0].year) + index).toString();
            return {
              ...section.data[0],
              year: year,
            };
          });
          return {
            ...section,
            data: updatedData,
          };
        });
        return {
          ...tab,
          sections: updatedSections,
        };
      });
      setFormData({ ...data, tabs: updatedTabs });
    }
  }, []);

  const handleAddNewRecord = (sectionName: string, tabName: string) => {
    const updatedTabs = formData?.tabs?.map((tab: any) => {
      if (tab.name === tabName) {
        return {
          ...tab,
          sections: tab.sections.map((section: any) => {
            if (section.name === sectionName) {
              const lastRecord = section.data[section.data.length - 1];
              const newYear = parseInt(lastRecord.year) + 1;
              const newRecord = {
                ...lastRecord,
                year: newYear.toString(),
                noOfJobs: { ...lastRecord.noOfJobs, value: "" },
                ...((tab.name === "stg_direct_jobs" ||
                  tab.name === "giga_direct_jobs" ||
                  tab.name === "init_direct_jobs") && {
                  nationalJobs: { ...lastRecord.nationalJobs, value: "" },
                }),
              };
              return {
                ...section,
                data: [...section.data, newRecord],
              };
            }
            return section;
          }),
        };
      }
      return tab;
    });
    setFormData((prevState: any) => ({
      ...prevState,
      tabs: updatedTabs,
    }));
  };

  useEffect(() => {
    if (formData?.tabs && formData.tabs.length > 0 && !alignValue) {
      setAlignValue(formData.tabs[0]?.name);
    }
  }, [formData, alignValue]);

  const getOption = () => {
    const formattedOptions =
      formData?.tabs?.map((option: any) => ({
        value: option?.name,
        label: option?.heading,
        subHeading: option?.subHeading,
        sections: option?.sections,
      })) || [];

    return formattedOptions;
  };

  const getTab = () => {
    const selectedItem = getOption()?.find(
      (item: any) => item.value === alignValue
    );
    return selectedItem;
  };

  return (
    <>
      <div className="job-creation-form w-100" style={{ height: "100%" }}>
        {data?.hasError && (
          <p className="sf-bold" style={{ color: Theme?.colors?.red }}>
            {data?.errorMsg}
          </p>
        )}
        <Segmented
          value={alignValue}
          style={{ marginBottom: 8 }}
          onChange={setAlignValue}
          options={getOption()}
        />
        <div className="p-2 job-title-div mt-3">
          <p className="job-type-heading m-0">{getTab()?.label}</p>
          <p className="job-type-description m-0">{getTab()?.subHeading}</p>
        </div>

        <div className="row mt-2">
          {getTab()?.sections?.map((section: any, index: number) => {
            return (
              <JobPhase
                props={section}
                type={getTab()?.value}
                handleValueChange={handleChange}
                handleTotalInputChange={handleTotalChange}
                handleAddNew={handleAddNewRecord}
              />
            );
          })}
        </div>

        <div className="form-bottom-div d-flex justify-content-between">
          <CButton
            className="floppy-btn"
            onClick={() => handleDraftForm(formData)}
          >
            <img src={Theme.icons.icn_floppy_disk} />
            {t("Save & Continue Later")}
          </CButton>
          <div className="form-footer-div">
            <CButton
              className="save-continue-btn w-75 mx-2"
              onClick={() => handleStepBack()}
            >
              <img src={Theme.icons.icn_arrow_left_blue} />
              {t("Back")}
            </CButton>
            <CButton
              className="next-btn"
              onClick={() => handleSubmitForm(formData)}
            >
              {t("Next Step")}
              <img src={Theme.icons.icn_arrow_right_white} />
            </CButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobCreationForm;
