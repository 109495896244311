import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CButton from "../../components/common/CButton";
import { Theme } from "../../Theme";
import { useTranslation } from "react-i18next";
import { CheckboxProps, Form, Switch } from "antd";
import PasswordInput from "../../components/common/PasswordInput";
import CInput from "../../components/common/CInput";
import { useMutation } from "react-query";
import { register } from "../../services/auth";
import CustomAlert from "../../components/common/CAlert";
import { verifyLink } from "../../services/Entity";
import LanguageSwitcher from "../../components/ui/LanguageSwitcher";
import AuthLanguage from "../../components/ui/auth/AuthLanguage";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import AuthIcon from "../../components/ui/auth/AuthIcons";
import { registerUser, verifyUserLink } from "../../services/User";

const Register: React.FC = () => {
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  const [userEmail, setUserEmail] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
    message: '',
    type: 'success',
    visible: false,
  });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { t } = useTranslation();
  const email = searchParams.get('email');
  const token = searchParams.get('token');
  const portal = searchParams.get('portal');

  const { mutate: callLink, isLoading: linkLoading, isError: isLinkError, error: linkError } = useMutation(verifyLink, {
    onSuccess: (response) => {
      const { entityType, name, spocEmail, spocName } = response?.data;
      setUserEmail(spocEmail);
      if (response?.statusCode === 200) {
        form.setFieldsValue({
          entityRepresentativeName: spocName,
          entityName: name,
          email: spocEmail,
          entityType: entityType
        })
      }
    },
    onError: (error: any) => {
      setAlert({ message: error ? (currentLanguage === 'en' ? error?.response?.data?.message : error?.response?.data?.messageAr) : t("An error occurred during login. Please try again."), type: 'error', visible: true });
    },
  });

  const { mutate: callUserLink, isLoading: linkUserLoading, isError: isLinkUserError, error: linkUserError } = useMutation(verifyUserLink, {
    onSuccess: (response) => {
      const { name, email, entity, role } = response?.data;
      setUserEmail(email);
      if (response?.statusCode === 200) {
        form.setFieldsValue({
          name: name,
          entityType: entity?.name,
          email: email,
          role: role.roleName
        })
      }
    },
    onError: (error: any) => {
      setAlert({ message: error ? (currentLanguage === 'en' ? error?.response?.data?.message : error?.response?.data?.messageAr) : t("An error occurred during login. Please try again."), type: 'error', visible: true });
    },
  });



  useEffect(() => {
    if (portal === 'admin') {
      callLink({ email, token });
    }else{
      callUserLink({token});
    }
  }, [location])


  const { mutate, isLoading, isError, error } = useMutation(( portal === 'admin' ?  register : registerUser), {
    onSuccess: (response) => {
      if (response?.statusCode === 200) {
        setAlert({ message: 'Register successful!', type: 'success', visible: true });
        navigate('/otp', { state: {email: userEmail, type: portal}  });
      }
    },
    onError: (error: any) => {
      setAlert({ message: error ? (currentLanguage === 'en' ? error?.response?.data?.message : error?.response?.data?.messageAr) : t("An error occurred during login. Please try again."), type: 'error', visible: true });
    },
  });

  const onFinish = (values: any) => {
    const obj = { email: values?.email, password: values?.password, confirmPassword: values?.confirmPassword };
    mutate(obj);
  }

  return (
    <>
      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
      <div className="auth-main-div d-flex justify-content-between mt-0">
        <div className="auth-form-div register-form-div">
          <p className="auth-heading m-0">{t("Register")}</p>
          <p className="auth-desc">{t("Register_description")}</p>

          <Form
            name="signUp"
            initialValues={{
              remember: true,
            }}
            form={form}
            onFinish={onFinish}
            autoComplete="off"
          >
         { portal === 'admin' ? (   <Form.Item
              name="entityRepresentativeName"
              rules={[
                {
                  required: false,
                  message: t('Please enter the entity representative name'),
                },
              ]}
            >
              <CInput
                label={t('Entity Representative Name')}
                placeholder={t('Enter Entity Representative Name')}
                disabled={true}
              />
            </Form.Item>) : (
              <Form.Item
              name="name"
              rules={[
                {
                  required: false,
                  message: t('Please enter the name'),
                },
              ]}
            >
              <CInput
                label={t('Name')}
                placeholder={t('Enter Name')}
                disabled={true}
              />
            </Form.Item>
            )}
            <Form.Item
              name="entityName"
              rules={[
                {
                  required: false,
                  message: t('Please enter the entity name'),
                },
              ]}
            >
              <CInput
                label={t('Entity Name')}
                placeholder={t('Enter Entity Name')}
                disabled={true}
              />
            </Form.Item>
          { portal === 'admin' ? (  <div className="row">
              <div className="col-sm-6">
                <Form.Item
                  // name="assignedRole"
                  rules={[
                    {
                      required: false,
                      message: t('Please enter the assigned role'),
                    },
                  ]}
                >
                  <CInput
                    label={t('Assigned Role')}
                    placeholder={t('Enter Assigned Role')}
                    value={t("Primary SPOC")}
                    disabled={true}
                  />
                </Form.Item>
              </div>
              {/* <div className="col-sm-6">
                <Form.Item
                  name="entityType"
                  rules={[
                    {
                      required: false,
                      message: t('Please enter the entity type'),
                    },
                  ]}
                >
                  <CInput
                    label={t('Entity Type')}
                    placeholder={t('Enter Entity Role')}
                    disabled={true}
                  />
                </Form.Item>
              </div> */}
            </div>) : (
              <Form.Item
              name="role"
              rules={[
                {
                  required: false,
                  message: t('Please enter the role'),
                },
              ]}
            >
              <CInput
                label={t('Role')}
                placeholder={t('Enter Role')}
                disabled={true}
              />
            </Form.Item>
            )}
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: t('email_error'),
                },
                {
                  pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: t('email_validation'),
                },
              ]}
            >
              <CInput
                label={t('email_address')}
                placeholder={t('email_placeholder')}
                disabled={true}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: t('password_error') },
                // { min: 8, message: t('password_validation') },
                {
                  pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-=+\[\]{};:'",.<>?\/|`~¢£¤¥€₹₩₱₿÷×√∞≈≠≤≥¬∧∨⊂⊃∈∉∪∩←↑→↓↔⇒⇔§©®™☑☹☺♥]).{8,}$/,
                  message: t('password_regex'),
                },
              ]}
              
            >
              <PasswordInput
                label={t('password')}
                placeholder={t('password_placeholder')}
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: t('confirm_password_error'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t('confirm_password_validation'))
                    );
                  },
                }),
              ]}
            >
              <PasswordInput
                label={t('confirm_password')}
                placeholder={t('password_placeholder')}
              />
            </Form.Item>
            <CButton loading={isLoading} disabled={false} className="mt-2 auth-btn" htmlType="submit">{t("Create")}</CButton>
          </Form>
          <div className="switch-form-div justify-content-center w-100 mt-2">
            <div className="align-items-center switch-card-div p-2">
              <LanguageSwitcher />
            </div>
          </div>
        </div>
        <div className="register-toggle-side">
        <AuthLanguage />
        </div>
      </div>
     <AuthIcon/>
      <p className="reserved-rights mt-3">
        {t("All rights reserved to the National Labor Observatory")}
      </p>
    </>

  );
};

export default Register;


