import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface User {
  id: number;
  name: string;
  roleName: string;
  entityId: number;
  entity: Entity;
  email: string;
}

interface Entity{
  name: string;
  entityType: string | "";
  details?: any;
}

interface AuthState {
  user: User | null;
  token: string | null;
  isAuthenticated: boolean;
  userType: string | null,
}

const initialState: AuthState = {
  user: null,
  token: null,
  userType: null,
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signin: (state, action: PayloadAction<{ user: User; token: string }>) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
    },
    setUserType: (state, action) => {
      state.userType = action.payload; 
    },
  },
});

export const { signin, logout, setUserType  } = authSlice.actions;

export default authSlice.reducer;
