import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CButton from "../../components/common/CButton";
import { Theme } from "../../Theme";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import PasswordInput from "../../components/common/PasswordInput";
import { useMutation } from "react-query";
import { reset } from "../../services/auth";
import CustomAlert from "../../components/common/CAlert";
import AuthLanguage from "../../components/ui/auth/AuthLanguage";
import LanguageSwitcher from "../../components/ui/LanguageSwitcher";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import AuthIcon from "../../components/ui/auth/AuthIcons";

const Reset: React.FC = () => {
    const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });
    const [resetPassword, setResetPassword] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const { resetPasswordToken, type } = location?.state || {};;




    const { mutate, isLoading, isError, error } = useMutation(reset, {
        onSuccess: (response) => {
            if (response?.statusCode === 200) {
                setAlert({ message: t("reset_success"), type: 'success', visible: true });
                setResetPassword(true);
            }
        },
        onError: (error: any) => {
            setAlert({ message: error ? (currentLanguage === 'en' ? error?.response?.data?.message : error?.response?.data?.messageAr) : t("An error occurred during login. Please try again."), type: 'error', visible: true });
        },
    });


    const onFinish = (values: any) => {
        let obj = {
            ...values,
            resetPasswordToken
        }
        mutate({ ...obj, type });
    }

    return (
        <>
            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
            <div className="auth-main-div d-flex justify-content-between">
                {resetPassword ? (
                    <div className="auth-form-div d-flex flex-column registration-auth-modal align-items-center reset-success-modal">
                        <img
                            src={Theme.images.img_registration_done}
                            className=""
                            alt="National Labour"
                            style={{ width: '150px' }}
                        />
                        <p className="auth-heading m-0">{t("Password Changed")}</p>
                        <p className="auth-desc w-75">{t("Your password has successfully changed, click the button below to proceed to login.")}</p>

                        <CButton loading={false} disabled={false} className="mt-4 auth-btn w-75" onClick={() => navigate(`/${type}/login`)}>{t("Proceed to Login")}</CButton>
                    </div>
                ) : (


                    <div className="auth-form-div mt-5 auth-reset-div">
                        <p className="auth-heading m-0">{t("Reset Password")}</p>
                        <p className="auth-desc">{t("Choose a new password for your account")}</p>

                        <Form
                            name="reset"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <Form.Item
                                name="password"
                                rules={[
                                    { required: true, message: t('password_error') },
                                    // { min: 8, message: t('password_validation') },
                                    {
                                        pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-=+\[\]{};:'",.<>?\/|`~¢£¤¥€₹₩₱₿÷×√∞≈≠≤≥¬∧∨⊂⊃∈∉∪∩←↑→↓↔⇒⇔§©®™☑☹☺♥]).{8,}$/,
                                        message: t('password_regex'),
                                    },
                                ]}
                            >
                                <PasswordInput
                                    label={t('password')}
                                    placeholder={t('password_placeholder')}
                                />
                            </Form.Item>
                            <Form.Item
                                name="confirmPassword"
                                dependencies={["password"]}
                                rules={[
                                    {
                                        required: true,
                                        message: t('confirm_password_error'),
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue("password") === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(t('confirm_password_validation'))
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <PasswordInput
                                    label={t('confirm_password')}
                                    placeholder={t('password_placeholder')}
                                />
                            </Form.Item>

                            <CButton loading={isLoading} disabled={false} className="mt-4 auth-btn" htmlType="submit">{t("Reset Password")}</CButton>
                        </Form>

                        <div className="switch-form-div justify-content-center w-100 mt-2">
                            <div className="align-items-center switch-card-div p-2">
                                <LanguageSwitcher />
                            </div>
                        </div>
                    </div>
                )}

                <AuthLanguage />
            </div>
          <AuthIcon/>
            <p className="reserved-rights mt-3">
                {t("All rights reserved to the National Labor Observatory")}
            </p>
        </>

    );
};

export default Reset;


