import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Checkbox, Tooltip } from "antd";
import CInput from "../../../../../components/common/CInput";
import CustomSelect from "../../../../../components/common/CSelect";
import CButton from "../../../../../components/common/CButton";
import CustomAlert from "../../../../../components/common/CAlert";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { useMutation } from "react-query";
import { getFourDigitInitiativeCode } from "../../../../../services/FormSubmission";
import { Theme } from "../../../../../Theme";

interface StrategyFormProps {
  data: any;
  handleSubmitForm: (
    form: any,
    isSubmit?: boolean,
    allowNext?: boolean
  ) => void;
  handleDraftForm: (form: any) => void;
  handleStepBack: () => void;
  isInitDataSave: boolean;
}

interface Data {
  [key: string]: {
    forms?: any[];
    subSector?: any;
    initiatives?: any;
    configuration?: any;
  };
}

const StrategyForm: React.FC<StrategyFormProps> = ({
  data,
  handleSubmitForm,
  handleDraftForm,
  handleStepBack,
  isInitDataSave,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [alert, setAlert] = useState<{
    message: string;
    type: string;
    visible: boolean;
  }>({
    message: "",
    type: "success",
    visible: false,
  });
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const entityName = useSelector(
    (state: RootState) => state?.auth?.user?.entity?.name
  );
  const entityType = useSelector(
    (state: RootState) => state?.auth?.user?.entity?.entityType
  );
    const formType = useSelector((state: RootState) => state?.submission?.type);
  const submissionData: Data | null = useSelector(
    (state: RootState) => state?.submission?.data as Data
  );

  const [formData, setFormData] = useState(data);

  const hasUnmounted = useRef(false);
  const { mutate, isLoading, isError, error } = useMutation(
    getFourDigitInitiativeCode,
    {
      onSuccess: (response) => {
        if (response?.statusCode === 200) {
          const updatedFormData = formData?.data?.map((item: any) => {
            if (item.name === "init_initiative_name") {
              return {
                ...item,
                value:
                  response?.data?.list[0]?.name !== undefined
                    ? response?.data?.list[0]?.name
                    : "No Name Found!",
                hasError:
                  response?.data?.list[0]?.name !== undefined ? false : true,
              };
            }
            return item;
          });
          setFormData({ ...formData, data: updatedFormData });
        }
      },
      onError: (error: any) => {
        setAlert({
          message: error
            ? error?.response?.data?.message
            : t("An error occurred during fetching. Please try again."),
          type: "error",
          visible: true,
        });
      },
    }
  );

  useEffect(() => {
    if (isInitDataSave) {
      handleSubmitForm(formData, false, true);
    }
  }, [isInitDataSave]);

  useEffect(() => {
    const updatedFormData = formData?.data?.map((item: any) => {
      if (item.name === "stg_strategy_name" || item.name === "giga_project_name") {
        return {
          ...item,
          value: submissionData?.[formType]?.configuration?.data?.submissionName,
          isReference: true
        };
      }
      return item;
    });
    setFormData({ ...formData, data: updatedFormData });
  }, []);

  const handleInputChange = (value: string, name: string) => {
    const updatedFormData = formData?.data?.map((item: any) => {
      if (item.name === name) {
        return {
          ...item,
          value,
          hasError: item.isRequired && value.trim() === "",
        };
      }
      return item;
    });
    setFormData({ ...formData, data: updatedFormData });
  };

  const handleMultiChoiceChange = (region: string, name: string) => {
    const updatedFormData = formData?.data?.map((item: any) => {
      if (item.name === name) {
        let newValue;

        if (region === "All Regions") {
          newValue = item.value.includes("All Regions") ? [] : ["All Regions"];
        } else if (region === "Not Applicable") {
          newValue = item.value.includes("Not Applicable")
            ? []
            : ["Not Applicable"];
        } else {
          newValue = item.value.includes(region)
            ? item.value.filter((r: string) => r !== region)
            : [...item.value, region];

          if (item.value.includes("All Regions")) {
            newValue = newValue.filter((r: string) => r !== "All Regions");
          }
          if (item.value.includes("Not Applicable")) {
            newValue = newValue.filter((r: string) => r !== "Not Applicable");
          }
        }

        return { ...item, value: newValue };
      }
      return item;
    });

    setFormData({ ...formData, data: updatedFormData });
  };

  const handleCheckboxInputChange = (
    value: string,
    name: string,
    region: string
  ) => {
    const updatedFormData = formData?.data?.map((item: any) => {
      if (item.name === name) {
        const updatedOptions = item.options.map((option: any) => {
          if (Array.isArray(option)) {
            if (option[0] === region) {
              return [option[0], value];
            }
          } else if (option === region) {
            return region;
          }
          return option;
        });
        const selectedValues = updatedOptions
        .filter((option: any) => Array.isArray(option) && option[1])
        .map((option: any) => parseFloat(option[1]));
      
      const totalSum = selectedValues.reduce((sum: number, current: number) => sum + current, 0);
      
      // Check if the total sum is within the limit
      if (totalSum > 100) {
        setAlert({
          message: 'Sum of percentages to be less than 100%.',
          type: "error",
          visible: true,
        });
        return item; // If the sum exceeds 100, return the original item
      }

        return { ...item, options: updatedOptions };
      }
      return item;
    });
    setFormData({ ...formData, data: updatedFormData });
  };

  const getOption = (options: any) => {
    const formattedOptions = options.map((option: any) => ({
      value: option,
      label: option,
    }));

    return formattedOptions;
  };

  const handleCallInitiativeCode = (value: string, name: string) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const stringValue = String(value);
    if (stringValue?.length <= 4) {
      const updatedFormData = formData?.data?.map((item: any) => {
        if (item.name === name) {
          return {
            ...item,
            value: stringValue,
            hasError: item.isRequired && stringValue.trim() === "",
          };
        }
        return item;
      });
      setFormData({ ...formData, data: updatedFormData });
    }
    if (stringValue?.length === 4) {
      const paddedValue = stringValue.padStart(4, "0");
      const timeoutId = setTimeout(() => {
        mutate({ search: paddedValue });
      }, 200);
      setTypingTimeout(timeoutId);
    }
  };
  return (
    <>
      <div className="row mx-1">
        <div className="col-lg-6 p-0">
          <span className="form-value-heading">{t("Description")}</span>
        </div>
        <div className="col-lg-6 p-0 d-flex justify-content-between">
          <span className="form-value-heading mx-2">{t("Value")}</span>
          <div style={{ marginRight: "20px" }}>
            <span style={{ color: "red" }}>*</span>
            <span className="form-mandatory-fields">
              {t("Mandatory fields")}
            </span>
          </div>
        </div>
      </div>
      {formData?.data?.map((item: any, index: number) => {
        return (
          <div key={index} className="row mx-1 mt-2">
            <div
              className={
                item?.dataType === "multichoice"
                  ? "col-lg-6 description-div align-items-start pt-3"
                  : "col-lg-6 description-div"
              }
            >
              {t(`${index + 1}. ${item?.description}`)}
              {item?.isRequired && <span style={{ color: "red" }}> *</span>}
            </div>
            <div className="col-lg-6 value-div">
              {item?.dataType === "text" ? (
                <Tooltip title={item?.hasError ? item?.errorMsg : ""}>
                  <CInput
                    placeholder={item?.placeHolder}
                    className={`form-input h-100 ${
                      item?.hasError ? "error-border" : ""
                    }`}
                    type={item?.dataType}
                    value={item?.isReference && item?.schema === 'entities' ? entityName : (item?.value ? item?.value : undefined )}
                    onChange={(e) =>
                      handleInputChange(e.target.value, item?.name)
                    }
                    disabled={
                      item?.isReference || item?.name === "init_initiative_name"
                    }
                  />
                </Tooltip>
              ) : item?.dataType === "dropdown" ? (
                <Tooltip title={item?.hasError ? item?.errorMsg : ""}>
                  <div>
                    <CustomSelect
                      className={`form-input h-100 ${
                        item?.hasError ? "error-border" : ""
                      }`}
                      options={getOption(item?.options)}
                      required={true}
                      {...(item?.value !== "" && { value: item?.value })}
                      onChange={(value) => handleInputChange(value, item?.name)}
                      placeholder={item?.placeHolder}
                    />
                  </div>
                </Tooltip>
              ) : item?.dataType === "number" && item?.minValue === 0 ? (
                <Tooltip title={item?.hasError ? item?.errorMsg : ""}>
                  <CInput
                    placeholder={item?.placeHolder}
                    className={`form-input h-100 w-50  ${
                      item?.hasError ? "error-border" : ""
                    }`}
                    suffix="%"
                    value={item?.value}
                    onChange={(e) => {
                      const value = Number(e.target.value);

                      if (!isNaN(value) && value >= 0 && value <= 100) {
                        handleInputChange(value?.toString(), item?.name);
                      }
                    }}
                    min={item?.minValue}
                    max={100}
                    type="text"
                    inputMode="numeric"
                  />
                </Tooltip>
              ) : item?.dataType === "number" ? (
                <Tooltip title={item?.hasError ? item?.errorMsg : ""}>
                  <CInput
                    placeholder={item?.placeHolder}
                    className={`form-input h-100 ${
                      item?.hasError ? "error-border" : ""
                    }`}
                    value={item?.value}
                    onChange={(e) => {
                      const value = Number(e.target.value);

                      if (!isNaN(value) && value >= 0) {
                            index === 0 && entityType === "VRPs"
                        ? handleCallInitiativeCode(value?.toString(), item?.name)
                        : handleInputChange(value?.toString(), item?.name);
                      }
                    }}
                    type="text"
                    inputMode="numeric"
                  />
                </Tooltip>
              ) : item?.dataType === "multichoice" ? (
                <div className="custom-checkbox-group description-div p-2">
                  {item?.options?.map((region: string, index: number) => {
                    return (
                      <Checkbox
                        key={index}
                        className={`custom-checkbox ${
                          item?.value?.includes(
                            Array.isArray(region) ? region[0] : region
                          )
                            ? "selected"
                            : ""
                        }`}
                        checked={item?.value?.includes(
                          Array.isArray(region) ? region[0] : region
                        )}
                        onChange={() =>
                          handleMultiChoiceChange(
                            Array.isArray(region) ? region[0] : region,
                            item?.name
                          )
                        }
                      >
                        <div className="d-flex align-items-center">
                          <span>
                            {Array.isArray(region) ? region[0] : region}
                          </span>
                          {Array.isArray(region) && (
                            <CInput
                              className="checkbox-input"
                              placeholder="0-100"
                              suffix="%"
                              disabled={ !item?.value?.includes(
                                Array.isArray(region) ? region[0] : region
                              )}
                              // value={region[1]}
                              onChange={(e) => {
                                const value = Number(e.target.value);

                                if (
                                  !isNaN(value) &&
                                  value >= 0 &&
                                  value <= 100
                                ) {
                                  handleCheckboxInputChange(
                                    value?.toString(),
                                    item?.name,
                                    region[0]
                                  );
                                }
                              }}
                              min={region[1]}
                              value={region[1] ? region[1] : region[1]}
                              max={100}
                              type="text"
                              inputMode="numeric"
                            />
                          )}
                        </div>
                      </Checkbox>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        );
      })}

      <div className="form-bottom-div d-flex justify-content-between">
        <CButton
          className="floppy-btn"
          onClick={() => handleDraftForm(formData)}
        >
          <img src={Theme.icons.icn_floppy_disk} />
          {t("Save & Continue Later")}
        </CButton>
        <div className="form-footer-div">
          <CButton
            className="save-continue-btn w-75 mx-2"
            onClick={() => handleStepBack()}
          >
            <img src={Theme.icons.icn_arrow_left_blue} />
            {t("Back")}
          </CButton>
          <CButton
            className="next-btn"
            onClick={() => handleSubmitForm(formData, false)}
          >
            {t("Next Step")}
            <img src={Theme.icons.icn_arrow_right_white} />
          </CButton>
        </div>
      </div>
      <CustomAlert
        message={alert.message}
        type={alert.type as "success" | "info" | "warning" | "error"}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
  );
};

export default StrategyForm;
