export const CapFirstLetter = (str: string): string => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  export const getDomain = (email: string) => {
    const match = email.match(/@.+$/); // Matches the '@' and everything after it
const domain = match ? match[0] : '';
return domain;
  }


  export function getNextWord(word: string) {
    const words = [
        "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten",
        "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen", "Twenty"
    ];
    const index = words.indexOf(word);
    return index !== -1 && index + 1 < words.length ? words[index + 1] : "One";
}

export function toCamelCase(input: string): string {
  return input
    .replace(/[-_\s]+(.)?/g, (_, char) => (char ? char.toUpperCase() : ''))
    .replace(/^[A-Z]/, (char) => char.toLowerCase());
}
  export const  capitalizeWords = (name: string) => {
    return name
        .split(' ') // Split the string into an array of words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
        .join(' '); // Join the words back into a string
}

//getBulkEntitySubmission
export const transformData = (data: any) => {
  const transformedEntities = data.entities.map((entity:any) => {
    // Group details by type
    const groupedDetails: { [key: string]: string[] } = {};

    entity.details.forEach((detail:any) => {
      if (!groupedDetails[detail.type]) {
        groupedDetails[detail.type] = [];
      }
      groupedDetails[detail.type].push(detail.value);
    });

    // Create the transformed structure for details
    const transformedDetails = Object.keys(groupedDetails).map(type => ({
      type,
      values: groupedDetails[type]
    }));

    return {
      ...entity,
      details: transformedDetails
    };
  });

  return {
    entities: transformedEntities,
    entity_files: data.entity_files
  };
};