import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumb, Button, Dropdown, Menu, Tag } from "antd";
import CInput from "../../../components/common/CInput";
import { Theme } from "../../../Theme";
import { CapFirstLetter } from "../../../utils/function";
import CButton from "../../../components/common/CButton";
import { FilterOutlined, DownOutlined } from '@ant-design/icons';
import CustomAlert from "../../../components/common/CAlert";
import CTable from "../../../components/common/CTable";
import { useMutation } from "react-query";
import _ from "lodash";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { getSubmissionList } from "../../../services/FormSubmission";



const DataSubmissionHistory: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState<any>({ list: [], pagination: {} });
    const { t } = useTranslation();
    const navigate = useNavigate();
    const pageNumber = Number(searchParams.get('PageNumber')) || 1;
    const pageSize = Number(searchParams.get('PageSize')) || 10;
    const search = searchParams.get('Search') || "";
    const status = searchParams.get('Status') || "";
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });

    const { mutate, isLoading, isError, error } = useMutation(getSubmissionList, {
        onSuccess: (response) => {
            if (response?.statusCode === 200) {
                setData({ ...data, list: response?.data?.list, pagination: response?.data?.pagination });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error ? error?.response?.data?.message : t("An error occurred during login. Please try again."), type: 'error', visible: true });
        },
    });

    const handleSearchChange = _.debounce((e: any) => {
        setSearchParams({ Status: status, PageNumber: '1', PageSize: pageSize.toString(), Search: e.target.value }, { replace: true });
    }, 1000)

    const handleMenuClick = (e: any) => {
        setSearchParams({ Status: e.key, PageNumber: '1', PageSize: pageSize.toString(), Search: search }, { replace: true });
    };

    const handleTableChange = (page: number, pageSize: number) => {
        setSearchParams({ Status: status, PageNumber: page.toString(), PageSize: pageSize.toString() }, { replace: true });
    };

    const handleMenuTable = (key: string, record: any) => {
        if (key === 'view') {
            // navigate(`/user/users/${key}`, { state: { type: key, record } })
        }
        if (key === 'edit') {
            navigate(`/user/submission/${key}`, { state: { type: key, record } })
        }
    };

    useEffect(() => {
        mutate({ search, status, pageNumber, pageSize });
    }, [search, status, pageNumber, pageSize])

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="Draft">{t("Draft")}</Menu.Item>
            <Menu.Item key="Submitted">{t("Submitted")}</Menu.Item>
        </Menu>
    );

    const MenuFunction = (record: any) => (
        <Menu onClick={({ key }) => handleMenuTable(key, record)}>
            <Menu.Item key="edit">
                Edit
            </Menu.Item>
        </Menu>
    );

    const columns: ColumnsType = [
        {
            title: t("Form ID"),
            dataIndex: 'id',
            key: 'id',
            align: 'center'
        },
        {
            title: t("Form Name"),
            dataIndex: 'entityName',
            key: 'name',
            sorter: (a, b) => a.name.length - b.name.length,
            render: (text, record, index) => (
                <span>{text ? CapFirstLetter(text) : 'N/A'}</span>
            ),
            // sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
        },
        {
            title: t("Version"),
            dataIndex: 'version',
            key: 'version',
            sorter: (a, b) => a.email.length - b.email.length,
        },
        {
            title: t("SPOC Name"),
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (text, record, index) => (
                <span>{record?.entity?.spocName}</span>
            ),
        },
        {
            title: t("Submission Date"),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text, record, index) => (
                <span>{moment(text).format("MMMM DD, YYYY")}</span>
            ),
        },
        {
            title: t("Approval Date"),
            dataIndex: 'approvalDate',
            key: 'approvalDate',
            render: (text, record, index) => (
                <span>{text ? moment(text).format("MMMM DD, YYYY") : 'N/A'}</span>
            ),
        },
        {
            title: t("Status"),
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (text, record, index) => (
                <Tag className="px-2 user-email" style={{ borderRadius: '25px', }} color={text === 'Draft' ? Theme.colors.blue : Theme.colors.gray}>{CapFirstLetter(text)}</Tag>

            ),
        },
     
        {
            title: t('Action'),
            render: (text, record, index) => (
                <Dropdown overlay={MenuFunction(record)} trigger={['click']} disabled={record?.status === "Submitted"}>
                    <img src={Theme.icons.icn_action} alt="icn_action" />
                </Dropdown>
            ),
            key: 'action',
            align: 'center'
        },
    ];

    const clearFilter = () => {
        setSearchParams({
            status: '',
            PageNumber: '1',
            PageSize: pageSize.toString(),
            Search: search
        });
    }


    return (

        <div className="round-content-div p-4 h-100">
            <div className=" px-1 d-flex justify-content-between">
                <div>
                    <p className="list-heading m-0">{t("Data Submission History")}</p>
                </div>
                <div className="filter-div">

                    <CInput
                        prefix={
                            <img alt="" src={Theme.icons.icn_search} style={{ height: "16px" }} />
                        }
                        className="searchInput"
                        placeholder={t("Search")}
                    onChange={handleSearchChange}
                    />

                    {/* <Dropdown overlay={menu} trigger={['click']} className="filter-btn">
                        <Button icon={<FilterOutlined />} style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="d-flex justify-content-between w-100">
                                <span>{status === '' ? t('Filters') : CapFirstLetter(status)}</span>
                                <DownOutlined />
                            </div>
                        </Button>
                    </Dropdown> */}

                     <Dropdown overlay={menu} trigger={['click']} className="filter-btn">
                                            <Button icon={<FilterOutlined />} style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className="d-flex justify-content-between w-100">
                                                    <span>{status === '' ? t('Filters') : CapFirstLetter(status)}</span>
                                                    <div className="d-flex align-items-center">
                                                        {status !== "" && <img src={Theme.icons.icn_cross} className="mx-2 filter-icon" onClick={() => clearFilter()} />}
                                                        <DownOutlined />
                                                    </div>
                                                </div>
                                            </Button>
                                        </Dropdown>


                    <CButton
                        className="add-button"
                        onClick={() => navigate('/user/submission/add')}
                        style={{
                            backgroundColor: Theme.colors.secondary
                        }}
                    >
                        <span className="mx-1 logout">{t("New Submission")}</span>
                    </CButton>
                </div>
            </div>
            
            <CTable
                columns={columns}
                dataSource={data?.list}
                pagination={{
                    showSizeChanger: false,
                    showQuickJumper: false,
                    current: data?.pagination.page,
                    pageSize: data?.pagination.per_page,
                    total: data?.pagination.count,
                    onChange: handleTableChange,
                    showTotal: (total: number, range: [number, number]) => (
                        <p>{`${range[0]} - ${range[1]} of ${total}`}</p>
                    ),
                    itemRender: (page, type, originalElement) => {
                        if (type === 'prev') {
                            return <CButton style={{ color: Theme.colors.white }}>{t("< Back")}</CButton>;
                        }
                        if (type === 'next') {
                            return <CButton style={{ color: Theme.colors.white }}>{t("Next >")}</CButton>;
                        }
                        return originalElement;
                    }
                }}
                loading={isLoading}
            />
            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
        </div>


    );
};

export default DataSubmissionHistory;
