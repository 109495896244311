import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CButton from "../../../components/common/CButton";
import { useTranslation } from "react-i18next";
import { Theme } from "../../../Theme";
import CInput from "../../../components/common/CInput";
import { Breadcrumb, Form } from "antd";
import CustomSelect from "../../../components/common/CSelect";
import SuccessModal from "../../../components/common/SuccessModal";
import { useMutation } from "react-query";
import CustomAlert from "../../../components/common/CAlert";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { createUser } from "../../../services/User";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { getDomain } from "../../../utils/function";


const CreateUser: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [success, setSuccess] = useState(false);
    const [email, setEmail] = useState('');
    const user = useSelector((state: RootState) => state?.auth?.user);
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });
    const [phone, setPhone] = useState<string>('');
    const location = useLocation();
    const { type, record } = location?.state || {};
    const domain = user ? getDomain(user?.email) : undefined;

    useEffect(() => {
        if (record) {
            setPhone(record?.entity?.mobileNumber);
            form.setFieldsValue({
                name: record?.name,
                email: record?.email.split('@')[0],
                mobileNumber: record?.entity?.mobileNumber,
                roleId: record?.roleId.toString(),
                // requestedBy: record?.requestedBy,

            })
        }
    }, [])

    const { mutate, isLoading, isError, error } = useMutation(createUser, {
        onSuccess: (response) => {
            if (response?.statusCode === 200) {
                setSuccess(true);
                setEmail(response?.data?.user?.email);
            }
        },
        onError: (error: any) => {
            setAlert({ message: error ? error?.response?.data?.message : t("An error occurred during login. Please try again."), type: 'error', visible: true });
        },
    });

    const validateEmail = (value: string) => {
        return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
    }
    const onFinish = (values: { [key: string]: any }) => {
        const emailWithDomain = `${values.email}${domain}`;
        const updatedValues = {
            ...values,
            email: emailWithDomain, // Replace the email field with the updated one
        };
        if (user) {
            const entityId = user?.entityId;
            const obj = { entityId, ...updatedValues, ...(record && { userId: record.id, status: record?.status }) };
            mutate({ obj, type });
        }
    }

    const Roles = [
        { value: '5', label: t("Secondary SPOC") },
        { value: '6', label: t("Standard Role") }];

    return (

        <div className="round-content-div p-4 h-100">
            <div className="d-flex justify-content-between">
                <div>
                    <p className="list-heading m-0">{t(`${type === 'view' ? 'View' : type === 'edit' ? 'Edit' : 'Create'} User`)}</p>
                    <Breadcrumb
                        separator=">"
                        items={[
                            {
                                title: t("Users"),
                            },
                            {
                                title: t('Users Overview'),
                            },
                            {
                                title: t('User Listing'),
                            },
                        ]}
                    />
                </div>

            </div>

            <div className="w-50 mt-3">
                <Form
                    name="createEntity"
                    initialValues={{
                        remember: true,
                    }}
                    form={form}
                    onFinish={onFinish}
                    autoComplete="off"
                    className="row"
                >
                    <div className="col-lg-6">
                        <Form.Item
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: t('Please enter the user name'),
                                },
                            ]}
                        >
                            <CInput
                                label={t('User Name')}
                                placeholder={t('Enter User Name')}
                                required={true}
                                disabled={type === 'view'}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: t('email_validation'),
                                    validator: (_, value) => {
                                        // Add the domain for validation
                                        const fullEmail = value ? `${value}${domain}` : '';
                                        if (validateEmail(fullEmail)) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject(t('email_error'));
                                        }
                                    },
                                },
                            ]}
                        >
                            <CInput
                                label={t('User Email')}
                                addonAfter={domain}
                                placeholder={t('Enter User Email')}
                                disabled={type === 'view' || type === 'edit'}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item
                            name="mobileNumber"
                            rules={[
                                {
                                    required: true,
                                    message: t('Please enter the mobile number'),
                                },
                            ]}
                        >
                            <label className='inputfield-label' style={{ display: 'block', marginBottom: '5px' }}>{t('Mobile Number')}<span style={{ color: 'red' }} >*</span></label>
                            <PhoneInput
                                country={'sa'}
                                onlyCountries={['sa']}
                                placeholder={t('Enter Mobile Number')}
                                value={phone}
                                onChange={(phone) => form.setFieldsValue({
                                    mobileNumber: phone
                                })}
                                disabled={type === 'view'}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item
                            name="roleId"
                            rules={[
                                {
                                    required: true,
                                    message: t('Please enter the role'),
                                },
                            ]}
                        >
                            <CustomSelect
                                label={t('Role')}
                                options={Roles}
                                placeholder={t('Select Role')}
                                required={true}
                                disabled={type === 'view'}
                            />
                        </Form.Item>
                    </div>

                    {type !== 'view' ? (<>
                        <div className="caution-div m-2">
                            <span className="ant-checkbox-wrapper" style={{ fontSize: '12px' }}>{t("An automated email with an invite link will be sent to the Entity Representative")}</span>
                        </div>

                        <div className="mt-2 d-flex">
                            <CButton className="form-btn" loading={isLoading} disabled={false} htmlType="submit">{t(`${type === 'view' ? 'View' : type === 'edit' ? 'Update' : 'Create'} User`)}</CButton>
                            <CButton className="form-btn cancel-btn mx-2" loading={false} disabled={false} onClick={() => navigate('/user/dashboard/users')}>{t("Cancel")}</CButton>
                        </div>
                    </>) : (
                        <div className="mt-2 d-flex">
                            <CButton className="form-btn" loading={false} disabled={false} onClick={() => navigate('/user/dashboard/users')}>{t("Back")}</CButton>
                        </div>
                    )}
                </Form>
            </div>
            <SuccessModal
                visible={success}
                heading={t(`User ${type === 'edit' ? "Updated" : "Added"}`)}
                message={type === 'edit' ? t("You have Successfully updated the User") : <>
                {t("You have successfully added new user and an signup link is shared at")} <strong>{email}</strong>
                </>}
                onOk={() => navigate('/user/dashboard/users')}
            />
            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
        </div>


    );
};

export default CreateUser;
