import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CButton from "../../../components/common/CButton";
import { useTranslation } from "react-i18next";
import { Theme } from "../../../Theme";
import { Breadcrumb, Form } from "antd";
import SuccessModal from "../../../components/common/SuccessModal";
import { useMutation } from "react-query";
import { createEntity } from "../../../services/Entity";
import CustomAlert from "../../../components/common/CAlert";
import "react-phone-input-2/lib/style.css";
import EntityCard from "./components/EntityCard";
import { RcFile } from "antd/es/upload";
import { transformData } from "../../../utils/function";
import { getFourDigitInitiativeCode } from "../../../services/FormSubmission";

interface Details {
  giga?: { values: string[] };
  strategy?: { values: string[] };
  vprs?: { values: string[] };
}

interface ResultItem {
  type: string;
  value: string;
}

const CreateEntity: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    entities: [
      {
        name: "",
        spocName: "",
        spocEmail: "",
        mobileNumber: "",
        fileName: "",
        details: [
          {
            type: "",
            value: "",
          },
        ],
      },
    ],
    entity_files: [],
  });

  const [alert, setAlert] = useState<{
    message: string;
    type: string;
    visible: boolean;
  }>({
    message: "",
    type: "success",
    visible: false,
  });
  const [openCardIndex, setOpenCardIndex] = useState<number | null>(0);

  const { type, record } = location?.state || {};

  useEffect(() => {
    if (record) {
      const result: ResultItem[] = [];

      // Iterate over the keys in 'details'
      const detailData: Details = record.details;
      for (const [type, data] of Object.entries(detailData)) {
        // Iterate over the values for each type
        data?.values?.forEach((value: any) => {
          result.push({ type, value });
        });
      }
      setFormData({
        entities: [
          {
            name: record?.name,
            spocName: record?.spocName,
            spocEmail: record?.spocEmail,
            mobileNumber: record?.mobileNumber,
            fileName: record?.assignmentProof,
            details: result,
          },
        ],
        entity_files: [],
      });
    }
  }, []);

  const { mutate, isLoading, isError, error } = useMutation(createEntity, {
    onSuccess: (response) => {
      if (response?.statusCode === 200) {
        setSuccess(true);
      }
    },
    onError: (error: any) => {
      setAlert({
        message: error
          ? error?.response?.data?.message
          : t("An error occurred during login. Please try again."),
        type: "error",
        visible: true,
      });
    },
  });

  const AddNewEntityHandler = () => {
    setFormData((prevFormData: any) => {
      const newData = [
        ...prevFormData.entities,
        {
          name: "",
          spocName: "",
          spocEmail: "",
          mobileNumber: "",
          fileName: "",
          details: [
            {
              type: "",
              values: [],
            },
          ],
        },
      ];
      setOpenCardIndex(newData.length - 1);
      return { ...prevFormData, entities: newData };
    });
  };

  const removeEntityHandler = (index: number) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      entities: prevFormData.entities.filter(
        (_: any, i: number) => i !== index
      ),
    }));
  };

  const handleCardToggle = (index: number) => {
    setOpenCardIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const AddNewSubmission = (index: number) => {
    setFormData((prevFormData: any) => {
      const updatedEntities = [...prevFormData.entities];
      updatedEntities[index].details.push({
        type: "",
        value: "",
      });

      return {
        ...prevFormData,
        entities: updatedEntities,
      };
    });
  };

  const handleInputChange = (
    value: string,
    index: number,
    fieldName: string
  ) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      entities: prevFormData.entities.map((entity: any, i: number) => {
        if (i === index) {
          return {
            ...entity,
            [fieldName]: value,
          };
        }
        return entity;
      }),
    }));
  };

  const onchangeFile = (info: any, index: number) => {
    const { file } = info;
    const newFile = file.originFileObj || file;
    setFormData((prevFormData: any) => {
      const updatedEntityFiles = [...prevFormData.entity_files];

      if (
        !updatedEntityFiles.some(
          (existingFile) => existingFile.name === newFile?.name
        )
      ) {
        updatedEntityFiles.push(newFile as RcFile);
      }

      return {
        ...prevFormData,
        entities: prevFormData.entities.map((entity: any, i: number) => {
          if (i === index) {
            return {
              ...entity,
              fileName: newFile?.name,
            };
          }
          return entity;
        }),
        entity_files: updatedEntityFiles, // Use the updated entity files array
      };
    });
  };

  const onSubmissionChange = (
    value: string,
    recordIndex: number,
    i: number,
    fieldName: string
  ) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      entities: prevFormData.entities.map(
        (entity: any, entityIndex: number) => {
          if (entityIndex === recordIndex) {
            const updatedDetails = entity.details.map(
              (detail: any, detailIndex: number) => {
                if (detailIndex === i) {
                  return {
                    ...detail,
                    [fieldName]: value,
                  };
                }
                return detail;
              }
            );
            return {
              ...entity,
              details: updatedDetails,
            };
          }
          return entity;
        }
      ),
    }));
  };

  const getFourDigitName = async (
    value: string,
    recordIndex: number,
    i: number,
    fieldName: string
  ) => {
    try {
      const response = await getFourDigitInitiativeCode({ search: value });
      if (response?.statusCode === 200) {
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          entities: prevFormData.entities.map(
            (entity: any, entityIndex: number) => {
              if (entityIndex === recordIndex) {
                const updatedDetails = entity.details.map(
                  (detail: any, detailIndex: number) => {
                    if (detailIndex === i) {
                      return {
                        ...detail,
                        [fieldName]:
                          response?.data?.list[0]?.name || "No Name Found!",
                      };
                    }
                    return detail;
                  }
                );
                return {
                  ...entity,
                  details: updatedDetails,
                };
              }
              return entity;
            }
          ),
        }));
      }
    } catch (error: any) {
      setAlert({
        message: error
          ? error?.response?.data?.message
          : t("An error occurred during fetching. Please try again."),
        type: "error",
        visible: true,
      });
    }
  };

  const handleRemoveSubmission = (recordIndex: number, detailIndex: number) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      entities: prevFormData.entities.map((entity: any, index: number) => {
        if (index === recordIndex) {
          return {
            ...entity,
            details: entity.details.filter(
              (_: any, i: number) => i !== detailIndex
            ),
          };
        }
        return entity;
      }),
    }));
  };

  const validateForm = () => {
    const isNameEmpty = formData.entities.some(entity => entity.name.trim() === "");
    if (isNameEmpty) {
      setAlert({
        message: 'Please enter the Entity Name',
        type: "error",
        visible: true,
      });
      return false;
    }

    const isDetailsValid = formData.entities.every((entity) => {
      return entity.details.some(
        (detail) => detail?.type?.trim() !== "" && detail?.value?.trim() !== ""
      );
    });

    if (!isDetailsValid) {
      setAlert({
        message: "Please fill in at least one valid submission (both type and name).",
        type: "error",
        visible: true,
      });
      return false;
    }

    return true;
  };


  const onsubmit = () => {
    if(validateForm()){
    const entityData = transformData(formData);
    const fd = new FormData();
    if(type === 'edit'){
      fd.append("details", JSON.stringify(entityData?.entities[0]?.details)); 
      fd.append("entityId", record?.id); 
    }else{  
      fd.append("entities_data", JSON.stringify(entityData?.entities));
    }
    let binaryData = formData?.entity_files;

    if (Array.isArray(binaryData)) {
      binaryData.forEach((file: RcFile) => {
        if (file) {
          fd.append("entity_files", file);
        }
      });
    } else {
      fd.append("entity_files", binaryData as RcFile);
    }
    mutate({ fd, type });
  }
  };

  return (
    <div className="round-content-div p-4 pb-0 h-100">
      <div className="d-flex justify-content-between">
        <div>
          <p className="list-heading m-0">
            {t(
              `${
                type === "view" ? "View" : type === "edit" ? "Edit" : "Create"
              } Entity`
            )}
          </p>
          <Breadcrumb
            separator=">"
            items={[
              {
                title: t("Entities"),
              },
              {
                title: t("Entity Overview"),
              },
              {
                title: t("Entity Listing"),
              },
            ]}
          />
        </div>
      </div>

      <div className="w-100 mb-4">
        {formData?.entities?.map((item: any, index: number) => {
          return (
            <EntityCard
              data={item}
              recordIndex={index}
              open={openCardIndex === index}
              onToggle={() => handleCardToggle(index)}
              handleInputChange={handleInputChange}
              removeEntityHandler={removeEntityHandler}
              totalLength={formData?.entities?.length}
              AddNewSubmission={AddNewSubmission}
              onchangeFile={onchangeFile}
              onSubmissionChange={onSubmissionChange}
              getFourDigitName={getFourDigitName}
              handleRemoveSubmission={handleRemoveSubmission}
            />
          );
        })}
        {location?.state === null && (
          <div className="mt-2 d-flex justify-content-end">
            <CButton
              className="add-new-year"
              onClick={() => AddNewEntityHandler()}
            >
              <img src={Theme.icons.icn_plus} />
              <span>{t("Add New Entity")}</span>
            </CButton>
          </div>
        )}
      </div>
      <div className={ type === 'view' ? "d-none" : "form-bottom-div d-flex justify-content-between p-0 py-3"}>
        <div className="caution-div p-0 m-2 w-50 d-flex align-items-center justify-content-center">
          <img src={Theme.icons.icn_caution} className="mx-1" height={15} />
          <span
            className="ant-checkbox-wrapper sf-semibold"
            style={{ fontSize: "12px" }}
          >
            {t(
              "An automated email with an invite link will be sent to the Entity Representative"
            )}
          </span>
        </div>
        <div className="d-flex">
          <CButton className="back-btn mx-2 px-4" onClick={() => navigate(-1)}>{t("Cancel")}</CButton>
          <CButton className="next-btn" loading={isLoading} style={{minWidth:'150px'}} onClick={() => onsubmit()}>
            {type === "edit" ?  t("Save Changes") : t("Save & Invite Entities")}
          </CButton>
        </div>
      </div>

      <SuccessModal
        visible={success}
        heading={t(`${type === "edit" ? "Entity Updated" : "Entities Added"}`)}
        message={
          type === "edit" ? (
            t("You have Successfully updated the Entity")
          ) : (
            <>
              {t(
                "You have successfully added new entities and a signup link is shared with respective SPOCs via email"
              )}{" "}
            </>
          )
        }
        onOk={() => navigate("/admin/dashboard/entities")}
      />
      <CustomAlert
        message={alert.message}
        type={alert.type as "success" | "info" | "warning" | "error"}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </div>
  );
};

export default CreateEntity;
