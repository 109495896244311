import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CInput from "../../../../../components/common/CInput";
import CButton from "../../../../../components/common/CButton";
import { Theme } from "../../../../../Theme";
import { Tooltip } from "antd";

interface JobPhaseColumn {
  nationalJobs?: string;
  noOfJobs?: string;
  year?: string;
}

interface section {
  nationalJobs: any;
  noOfJobs: any;
  year: string;
}

interface JobPhaseCreation {
  heading?: string;
  name?: string;
  data?: section[];
  columns?: JobPhaseColumn;
  subHeading?: string;
  total_national_jobs: any;
  total_number_jobs: any;
}

interface JobPhaseProps {
  props: JobPhaseCreation | undefined;
  type: string;
  handleValueChange: (
    value: string,
    name: string,
    year: string,
    sectionName: string,
    tabName: string
  ) => void;
  handleAddNew: (sectionName: string, tabName: string) => void;
  handleTotalInputChange: (value:any, fieldName:any, type:any, name:any) => void;
}

const JobPhase: React.FC<JobPhaseProps> = ({
  props,
  type,
  handleValueChange,
  handleAddNew,
  handleTotalInputChange
}) => {
  const {
    heading = "",
    name = "",
    subHeading = "",
    total_national_jobs,
    total_number_jobs,
  } = props || {};
  const { nationalJobs = "", noOfJobs = "", year = "" } = props?.columns || {};

  const navigate = useNavigate();
  const { t } = useTranslation();

//   const [recordCount, setRecordCount] = useState(0);
//   // This effect will trigger once when the component mounts and add 10 records
//   useEffect(() => {
//     if (recordCount < 10) {
//       handleAddNew(name, type);
//       setRecordCount(recordCount + 1); // Update state after adding each record
//     }
//   }, [recordCount]);
  return (
    <>
      <div className="col-lg-6 mt-2">
        <div className="w-100 d-flex flex-column align-items-center job-phase-div py-2">
          <span>{heading}</span>
          <span>{subHeading}</span>
        </div>
        <div
          className={`${
            (type === "stg_direct_jobs" ||
              type === "init_direct_jobs" ||
              type === "giga_direct_jobs") &&
            heading === "Operational Phase"
              ? "operational-direct-phase"
              : "operational-indirect-phase"
          } mt-2`}
        >
          <div className="job-phase-div text-center py-1">
            <span>{year}</span>
          </div>
          <div className="job-phase-div text-center py-1">
            <span>{noOfJobs}</span>
          </div>
          {(type === "stg_direct_jobs" ||
            type === "init_direct_jobs" ||
            type === "giga_direct_jobs") &&
            heading === "Operational Phase" && (
              <div className="job-phase-div text-center py-1">
                <span>{nationalJobs}</span>
              </div>
            )}
          {props?.data?.map((item: any, i) => {
            return (
              <>
                <div
                  className="job-phase-div text-center py-1"
                  style={{ backgroundColor: "#F8FAFC" }}
                >
                  <span>{item?.year}</span>
                </div>
                <div>
                  <CInput
                    className={`year-input ${
                      item?.noOfJobs?.hasError ? "error-border" : ""
                    }`}
                    placeholder={item?.noOfJobs?.placeHolder}
                    value={item?.noOfJobs?.value}
                    onChange={(e) =>
                      handleValueChange(
                        e.target.value,
                        item?.noOfJobs?.name,
                        item?.year,
                        name,
                        type
                      )
                    }
                    max={100}
                    type={item?.noOfJobs?.dataType}
                  />
                </div>
                {(type === "stg_direct_jobs" ||
                  type === "init_direct_jobs" ||
                  type === "giga_direct_jobs") &&
                  heading === "Operational Phase" && (
                    <div>
                      <Tooltip
                        title={
                          item?.nationalJobs?.hasError
                            ? item?.nationalJobs?.errorMsg
                            : ""
                        }
                      >
                        <CInput
                          className={`year-input ${
                            item?.nationalJobs?.hasError ? "error-border" : ""
                          }`}
                          placeholder={item?.nationalJobs?.placeHolder}
                          value={item?.nationalJobs?.value}
                          //   className={`form-input h-100 w-50 ${item?.hasError ? 'error-border' : ''}`}
                          // suffix="%"
                          onChange={(e) =>
                            handleValueChange(
                              e.target.value,
                              item?.nationalJobs?.name,
                              item?.year,
                              name,
                              type
                            )
                          }
                          //   min={item?.minValue}
                          max={100}
                          type={item?.nationalJobs?.dataType}
                        />
                      </Tooltip>
                    </div>
                  )}
              </>
            );
          })}
        </div>
        {/* <div className="mt-2 d-flex justify-content-end">
          <CButton
            className="add-new-year"
            onClick={() => handleAddNew(name, type)}
          >
            <img src={Theme.icons.icn_plus} />
            <span>{t("Add New")}</span>
          </CButton>
        </div> */}
        <div
          className={`${
            (type === "stg_direct_jobs" ||
              type === "init_direct_jobs" ||
              type === "giga_direct_jobs") &&
            heading === "Operational Phase"
              ? "operational-direct-phase"
              : "operational-indirect-phase"
          } mt-2`}
        >
          <div
            className="job-phase-div text-center py-1"
            style={{ height: "min-content" }}
          >
            <span>Total</span>
          </div>
          <CInput
            className="job-phase-div text-start py-1"
            placeholder={total_number_jobs?.placeHolder}
            type={total_number_jobs?.dataType}
            value={total_number_jobs?.value}
            onChange={(e) => handleTotalInputChange(e.target.value, 'total_number_jobs', name, type)}
            style={{ height: "min-content" }}
          />
          {(type === "stg_direct_jobs" ||
            type === "init_direct_jobs" ||
            type === "giga_direct_jobs") &&
            heading === "Operational Phase" && (
              <CInput
                className="job-phase-div text-start py-1"
                placeholder={total_national_jobs?.placeHolder}
                type={total_national_jobs?.dataType}
                value={total_national_jobs?.value}
                // inputMode="numeric"
                onChange={(e) => handleTotalInputChange(e.target.value, 'total_national_jobs', name, type)}
                style={{ height: "min-content" }}
              />
            )}
        </div>
      </div>
    </>
  );
};

export default JobPhase;
