import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import CModal from './CModal';
import CButton from './CButton';
import { Theme } from '../../Theme';
import { useTranslation } from 'react-i18next';

interface ConfirmActivationModalProps {
  visible: boolean;
  message?: string;
  onCancel: () => void;
  onConfirm: () => void;
  loading?: boolean;
}

const AlertModal: React.FC<ConfirmActivationModalProps> = ({
  visible,
  message,
  onCancel,
  onConfirm,
  loading
}) => {
  const { t } = useTranslation();
  return (
    <CModal
      visible={visible}
      onCancel={onCancel}
      footer={null} // To use custom footer buttons
      centered
      title={null}
      className='success-modal'
    >
      <div style={{ textAlign: 'center' }}>
      <img src={Theme.images.img_registration_done} alt="logo" className="modal-logo" />
        <h2 className='auth-heading mt-2'>Alert</h2>
        <p className='auth-desc mx-3'>{message}</p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
    
        {/* <CButton className='cancelBtn' onClick={onCancel}>
                            Cancel
                        </CButton>
                 
                        <CButton style={{ width: '100%' }} onClick={onDelete}>
                            Save
                        </CButton> */}
                         <div className="form-footer-div d-flex justify-content-between">
                <CButton className="back-btn px-4" onClick={onCancel}>{t("Back")}</CButton>
                    <CButton className="next-btn mx-2" onClick={onConfirm} loading={loading} style={{minWidth:'85px'}}>{t("Confirm")}</CButton>
                </div>
        </div>
      </div>
    </CModal>
  );
};

export default AlertModal;
