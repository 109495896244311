import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import CustomSelect from "../../../../../components/common/CSelect";
import { useTranslation } from "react-i18next";
import CButton from "../../../../../components/common/CButton";
import { Checkbox, Descriptions, Radio } from "antd";
import CInput from "../../../../../components/common/CInput";
import { Theme } from "../../../../../Theme";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { CapFirstLetter } from "../../../../../utils/function";
import { removeSubSector } from "../../../../../store/slices/dataSubmissionSlice";
import { getFormData } from "../../../../../services/FormSubmission";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import AlertModal from "../../../../../components/common/AlertModal";
import { get } from "lodash";
import { getProfile } from "../../../../../services/Entity";
import { signin } from "../../../../../store/slices/authSlice";

interface ConfigurationsProps {
  data: any;
  handleGetJsonData: (type: any) => void;
  handleSubmitForm: (form: any, isSubmit?: boolean) => void;
  handleChangeConfiguration: () => void;
  setAlert: React.Dispatch<
    React.SetStateAction<{ message: string; type: string; visible: boolean }>
  >;
  handleRemoveAlignValue: () => void;
  handleDisableMenu: (disabled: any) => void;
}

interface Data {
  [key: string]: {
    forms?: any[];
    subSector?: any;
    initiatives?: any;
    configuration?: any;
  };
}


const ConfigurationsForm: React.FC<ConfigurationsProps> = ({
  data,
  handleSubmitForm,
  handleGetJsonData,
  handleChangeConfiguration,
  setAlert,
  handleRemoveAlignValue,
  handleDisableMenu
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState({flag: false, index: 0});
  const [formData, setFormData] = useState(data);
  const [checked, setChecked] = useState(data?.data?.breakDown);
  const [initForm, setInitForm] = useState([]);
  const type = location?.pathname?.split("/")[3];
  const subSectorDetails = useSelector(
    (state: RootState) => state?.auth?.user?.entity?.details
  );
  const configuration: any = useSelector(
    (state: RootState) => state?.submission?.configuration
  );
  const initialCopy: any = useSelector(
    (state: RootState) => state?.submission?.initiativeCopy
  );
    const entityType = useSelector(
      (state: RootState) => state?.submission?.type
    );
     const submissionData: Data | null = useSelector(
        (state: RootState) => state?.submission?.data as Data
      );

  const getSubmissionValue = (type: string) => {
    const options = subSectorDetails?.[type]?.values?.map((value: any) => {
      const label = value
        .split(" ")
        .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      return { label: label, value: value };
    });
    return options;
  };

  const getSubmissionType = () => {
    const keys = Object.keys(subSectorDetails);
    const submissionType = keys.map((key) => {
      let label = key.charAt(0).toUpperCase() + key.slice(1);
      if (key === "giga") {
        label = "Giga Project";
      } else if (key === "strategy") {
        label = "Strategy";
      } else if (key === "vprs") {
        label = "VRPs";
      }
      return { value: key, label: label };
    });
    return submissionType;
  };

  const AddNewSubmission = async () => {
    setFormData((prevFormState: any) => ({
      ...prevFormState,
      data: {
        ...prevFormState.data,
        details: [
          ...prevFormState.data.details,
          {
            value: `sector-${prevFormState.data.details.length + uuidv4()}`,
            name: "",
            heading: "",
            data: type === 'edit' ? initForm : initialCopy,
          },
        ],
      },
    }));
  };

  const removeFromSubSector = (index: number) => {
    dispatch(removeSubSector(index));
  }

  const handleRemoveSector = (index: number) => {
    handleDisableMenu(true);
    setFormData((prevFormState: any) => ({
      ...prevFormState,
      data: {
        ...prevFormState.data,
        details: prevFormState.data.details.filter(
          (_: any, idx: number) => idx !== index
        ),
      },
    }));
    if(submissionData?.[entityType]?.subSector){
      if(index == 0){
        handleRemoveAlignValue();
      }
      removeFromSubSector(index);
    }
    setIsConfirmModalVisible({...isConfirmModalVisible, flag:false});
  };

  const handleDetailChange = (index: number, field: string, value: string) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      data: {
        ...prevFormData.data,
        details: prevFormData.data.details.map((detail: any, idx: number) =>
          idx === index
            ? { ...detail, [field]: value } // Update either name or heading
            : detail
        ),
      },
    }));
  };


  const validateForm = () => {
    const isSubmissionTypeEmpty = formData.data.submissionType.trim() === "";
    if (isSubmissionTypeEmpty) {
      setAlert({
        message: "Please enter the Submission Type",
        type: "error",
        visible: true,
      });
      return false;
    }

    const isSubmissionNameEmpty = formData.data.submissionName.trim() === "";
    if (isSubmissionNameEmpty) {
      setAlert({
        message: "Please enter the Submission Name",
        type: "error",
        visible: true,
      });
      return false;
    }

    const isDetailsValid = formData.data.details?.every((detail: any) => {
      return detail.name.trim() !== "";
    });

    if (formData.data.breakDown && !isDetailsValid) {
      setAlert({
        message: "Please fill in the details (name).",
        type: "error",
        visible: true,
      });
      return false;
    }

    // If all checks pass
    return true;
  };

  const onSubmitConfiguration = () => {
    if (validateForm()) {
      handleChangeConfiguration();
      handleSubmitForm(formData, false);
    }
  };

    const { mutate, isLoading, isError, error } = useMutation( getFormData,
      {
        onSuccess: (response) => {
          if (response?.statusCode === 200) {
            setInitForm(response?.data.jsonData?.[entityType]?.forms);
          }
        },
        onError: (error: any) => {
          setAlert({
            message: error
              ? error?.response?.data?.message
              : t("An error occurred during login. Please try again."),
            type: "error",
            visible: true,
          });
        },
      }
    );

    useEffect(() => {
if(type === 'edit'){
  mutate({type: entityType})
}
    },[location])

    const { mutate:getMyProfile, isLoading: profileLoading, isError: profileError } = useMutation(getProfile, {
      onSuccess: (response) => {
          if (response?.statusCode === 200) {
              const token = localStorage.getItem("authToken") || "";
              const userData = {
                  user: response.data.user,
                  token: token,
              };
              dispatch(signin(userData));
          }
      },
      onError: (error: any) => {
          console.error("Error fetching profile:", error?.response?.data?.message);
      },
  });
  

  useEffect(() => {
    getMyProfile({});
  },[])


  return (
    <>
      <div className="row">
        <div className="col-lg-4">
          <CustomSelect
            label={t("Submission Type")}
            options={getSubmissionType()}
            required={true}
            value={
              formData?.data?.submissionType
                ? formData?.data?.submissionType
                : undefined
            }
            disabled={type === "edit"}
            placeholder={t("Select Submission Type")}
            onChange={(value) => {
              setFormData((prevFormData: any) => ({
                ...prevFormData,
                data: {
                  ...prevFormData.data,
                  submissionType: value,
                  submissionName: "",
                },
              }));
              handleGetJsonData(value);
            }}
          />
        </div>
        {formData?.data?.submissionType !== "" && (
          <div className="col-lg-4">
            <CustomSelect
              label={t(
                `Select ${
                  formData?.data?.submissionType === "giga"
                    ? "Giga Project"
                    : CapFirstLetter(formData?.data?.submissionType)
                }`
              )}
              required={true}
              value={
                formData?.data?.submissionName
                  ? formData?.data?.submissionName
                  : undefined
              }
              options={getSubmissionValue(formData?.data?.submissionType)}
              disabled={
                formData?.data?.submissionType === "" || type === "edit"
              }
              placeholder={t(
                `Select ${
                  formData?.data?.submissionType === "giga"
                    ? "Giga Project"
                    : formData?.data?.submissionType
                }`
              )}
              onChange={(value) =>
                setFormData((prevFormData: any) => ({
                  ...prevFormData,
                  data: {
                    ...prevFormData.data,
                    submissionName: value,
                  },
                }))
              }
            />
          </div>
        )}
      </div>
      <div className="break-down-div p-3 d-flex justify-content-between align-items-center mt-3">
        <div>
          <p className="m-0 job-type-description">
            {t(
              `Break Down ${
                formData?.data?.submissionType === "giga"
                  ? "Giga project"
                  : CapFirstLetter(formData?.data?.submissionType)
              } Into sub-sectors?`
            )}
          </p>
          <p className="w-75 m-0 break-down-description">
            {t(
              "If this strategy has multiple sectors with different data for each sector, you can breakdown the submission of your strategy into various sectors."
            )}
          </p>
        </div>
        <div className="d-flex">
          <CButton
            className="mx-2 bg-white"
            onClick={() => {
              if(formData?.data?.submissionType !== ''){
              setChecked(true);
              setFormData((prevFormData: any) => ({
                ...prevFormData,
                data: {
                  ...prevFormData.data,
                  breakDown: true,
                },
              }));
              //   handleGetJsonData(formData?.data?.submissionType);
              if (formData?.data?.details?.length === 0) {
                AddNewSubmission();
              }
            }else{
              setAlert({
                message: "Please select the Submission Type",
                type: "error",
                visible: true,
              });
            }
            }}
            
          >
            <Radio checked={checked} />
            <span className="job-type-description">{t("Yes")}</span>
          </CButton>
          <CButton
            className=" bg-white"
            onClick={() => {
              setChecked(false);
              setFormData((prevFormData: any) => ({
                ...prevFormData,
                data: {
                  ...prevFormData.data,
                  breakDown: false,
                },
              }));
                handleGetJsonData(formData?.data?.submissionType)
            }}
          >
            <Radio checked={!checked} />
            <span className="job-type-description">{t("No")}</span>
          </CButton>
        </div>
      </div>
      {formData?.data?.breakDown && (
        <div className="sub-sector-div p-3 mt-3">
          <p className="spoc-assignment-proof mb-0">
            {t("Sub-Sector Details")}
          </p>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "5fr 16fr 1fr",
              gridGap: "10px",
            }}
          >
            <div>
            <span className="submission-form-type">
              {t("Sub-sector Name")}
            </span>
            <span style={{ color: "red" }}>*</span>
            </div>
            <span className="submission-form-type">{t("Description")}</span>
          </div>
          {formData?.data?.details?.map((item: any, i: number) => {
            return (
              <div
                key={i}
                style={{
                  display: "grid",
                  gridTemplateColumns: "5fr 16fr 1fr",
                  gridGap: "10px",
                }}
                className="mt-2"
              >
                <CInput
                  placeholder={t("Add Name")}
                  value={item?.name}
                  onChange={(e) =>
                    handleDetailChange(i, "name", e.target.value)
                  } // Update the 'name' field
                />
                <CInput
                  placeholder={t("Add Heading")}
                  value={item?.heading}
                  onChange={(e) =>
                    handleDetailChange(i, "heading", e.target.value)
                  } // Update the 'heading' field
                />
                <div
                  className="d-flex align-items-center justify-content-center cursor-pointer"
                  style={{ backgroundColor: "#FEF2F2", borderRadius: "8px" }}
                  onClick={() =>
                    formData?.data?.details?.length !== 1 &&
                    setIsConfirmModalVisible({flag: true, index: i})
                  }
                >
                  <img src={Theme?.icons?.icn_delete} />
                </div>
              </div>
            );
          })}
          <div className={"mt-3 d-flex justify-content-end"}>
            <CButton
              className="add-new-year cursor-pointer"
              onClick={() => AddNewSubmission()}
            >
              <img src={Theme.icons.icn_plus} />
              <span>{t("Add New")}</span>
            </CButton>
          </div>
        </div>
      )}
      <div className="form-bottom-div d-flex justify-content-end">
        <div className="d-flex ">
          <CButton className="back-btn mx-2" onClick={() => navigate(-1)}>{t("Cancel")}</CButton>
          <CButton className="next-btn" onClick={() => onSubmitConfiguration()}>
            {t("Continue")}
          </CButton>
        </div>
      </div>

      <AlertModal
        visible={isConfirmModalVisible?.flag}
        onCancel={() => setIsConfirmModalVisible({...isConfirmModalVisible, flag: false})}
        onConfirm={() => handleRemoveSector(isConfirmModalVisible?.index) }
        message={'Are you Sure, you want to delete the sub-sector'}
        // loading={submitLoading}
      />
    </>
  );
};

export default ConfigurationsForm;
