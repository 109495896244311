import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Theme } from "../../../../../Theme";
import CInput from "../../../../../components/common/CInput";
import CButton from "../../../../../components/common/CButton";
import AlertModal from "../../../../../components/common/AlertModal";
import PhoneInput from "react-phone-input-2";
import { Upload } from "antd";
import CustomSelect from "../../../../../components/common/CSelect";
import { CapFirstLetter } from "../../../../../utils/function";
import { getAllVrps } from "../../../../../services/FormSubmission";
import { useMutation } from "react-query";

interface EntityCardProps {
    data: any;
  open: boolean;
  onToggle: () => void;
  recordIndex: number;
  totalLength: number;
  entityType: string;
  type: string;
}

const Alerts: React.FC<EntityCardProps> = ({
    data,
  recordIndex,
  open,
  onToggle,
  totalLength,
  entityType,
  type
}) => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-3 mt-2 entity-user-row cursor-pointer"
        onClick={onToggle}
      >
        <div className="d-flex align-items-center">
          <p className="m-0 entity-user-heading sf-bold">{type === 'subSector' ? `Sub-Sector #${recordIndex+1}` : `${CapFirstLetter(entityType == 'giga' ? 'Giga Project' : entityType)} #`}</p>
          <p className="m-0 mx-3 sf-regular entity-user-heading">
            {data?.heading}
          </p>
        </div>
        <div className="d-flex align-items-center">
          <p className="m-0 entity-user-heading sf-bold">
            {"Total Direct Sustainable Jobs"}
          </p>
          <p className="m-0 mx-3 sf-regular entity-user-heading">{data?.sustainable_jobs ? data?.sustainable_jobs : "N/A"}</p>
        </div>

        <div
          className="entity-tag px-2 d-flex align-items-center mx-2"
          style={{ backgroundColor: "#FEE2E2", height: "35px" }}
        >
          <div
            className="no-of-entity-tag mx-1"
            style={{ backgroundColor: "#EF4444" }}
          >
            <span>{data?.alerts?.length}</span>
          </div>
          <span className="entity-tag-title mx-1" style={{ color: "#EF4444" }}>
            {t("Alert")}
          </span>
          <img className={`mx-2 ${open && 'alert_rotate-up'}`} src={Theme.icons.icn_dropdown_red} />
        </div>
      </div>
      {open && (
        <div
          className="isis-mapping-detail p-3"
          style={{
            backgroundColor: "#FEF2F2",
            borderStyle: "none",
            borderRadius: "0px",
          }}
        >
          <p
            className="spoc-assignment-proof mt-2 mb-0"
            style={{ color: "#EF4444" }}
          >
            {t("Alerts")}
          </p>
{data?.alerts?.map((item:any, index:number) => (
   <div className="child-alerts d-flex justify-content-between align-items-center mt-2">
   <div className="d-flex align-items-start">
     <img src={Theme?.icons?.icn_warning_red} />
     <div className="mx-2">
       <p
         className="spoc-assignment-proof"
         style={{ color: "#EF4444", margin: "auto", fontSize: "14px" }}
       >
         {item?.heading}
       </p>
       <span className="sf-regular" style={{ fontSize: "13px" }}>
        {item?.description}
       </span>
     </div>
   </div>

   <div
     className="entity-tag px-2 d-flex align-items-center mx-1"
     style={{ backgroundColor: "#FEE2E2", height: "35px" }}
   >
     <span
       className="spoc-assignment-proof  mx-1 sf-bold"
       style={{ color: "#EF4444" }}
     >
       {`${item?.deduction}%`}
     </span>
     <span
       className="entity-tag-title mx-1"
       style={{ color: "#EF4444" }}
     >
       {t("Deduction")}
     </span>
   </div>
 </div>
))}
        </div>
      )}
    </>
  );
};

export default Alerts;
