import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Checkbox, Tooltip } from "antd";
import CInput from "../../../../../components/common/CInput";
import CustomSelect from "../../../../../components/common/CSelect";
import CButton from "../../../../../components/common/CButton";
import CustomAlert from "../../../../../components/common/CAlert";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { useMutation } from "react-query";
import { getFourDigitInitiativeCode } from "../../../../../services/FormSubmission";
import { Theme } from "../../../../../Theme";
import Alerts from "../ui/Alerts";
import { formSubmissionType } from "../../../../../store/slices/dataSubmissionSlice";

interface AlertsFormProps {
  handleSubmitForm: (form: any, isSubmit?: boolean) => void;
  handleDraftForm: (form: any) => void;
  handleStepBack: () => void;
}

interface Data {
  [key: string]: {
    forms?: any[];
    subSector?: any;
    initiatives?: any;
    configuration?: any;
  };
}

const AlertsForm: React.FC<AlertsFormProps> = ({
  handleSubmitForm,
  handleDraftForm,
  handleStepBack,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [alert, setAlert] = useState<{
    message: string;
    type: string;
    visible: boolean;
  }>({
    message: "",
    type: "success",
    visible: false,
  });
  const dispatch = useDispatch();
  const submissionData: Data | null = useSelector(
    (state: RootState) => state?.submission?.data as Data
  );
  const entityType = useSelector((state: RootState) => state?.submission?.type);
  const [openCardIndex, setOpenCardIndex] = useState<number | null>(0);

  const alertData = submissionData?.[entityType]?.forms?.filter(
    (key) => key?.name == "Alerts"
  );
  const subSectorAlerts = submissionData?.[entityType]?.subSector
    ?.map((item: any) => {
      const alerts = item?.data?.find((key: any) => key?.name === "Alerts");
      return alerts ? alerts : null;
    })
    .filter(Boolean);

  const handleCardToggle = (index: number) => {
    setOpenCardIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  
    const handleSubmit = () => {
      dispatch(
        formSubmissionType({
          type: true,
        })
      );
  
      handleSubmitForm({}, true);
    };

  const finalAlerts = submissionData?.[entityType]?.forms
    ? alertData
    : subSectorAlerts;

  return (
    <>
      <p className="header-heading">Alerts</p>
      <div className="mt-2">
        {finalAlerts?.map((item: any, i: number) => (
          <Alerts
            key={i}
            data={item}
            recordIndex={i}
            open={openCardIndex === i}
            onToggle={() => handleCardToggle(i)}
            totalLength={0}
            entityType={entityType}
            type={
              submissionData?.[entityType]?.forms ? "singleForm" : "subSector"
            }
          />
        ))}
      </div>
      <div className="form-bottom-div d-flex justify-content-between">
        <CButton
          className="floppy-btn"
          onClick={() => handleDraftForm({})}
        >
          <img src={Theme.icons.icn_floppy_disk} />
          {t("Save & Continue Later")}
        </CButton>
        <div className="form-footer-div">
          {/* <CButton
            className="save-continue-btn w-75 mx-2"
            onClick={() => handleStepBack()}
          >
            <img src={Theme.icons.icn_arrow_left_blue} />
            {t("Back")}
          </CButton> */}
          <CButton className="next-btn" onClick={() => handleSubmit()}>
            {t("Submit")}
            <img src={Theme.icons.icn_arrow_right_white} />
          </CButton>
        </div>
      </div>
    </>
  );
};

export default AlertsForm;
