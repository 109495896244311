import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../store/store";
import { useMutation } from "react-query";
import { getProfile } from "../services/Entity";
import { signin } from "../store/slices/authSlice";
import { Spin } from "antd";

const useAuth = () => {
    const user = localStorage.getItem("authToken");
    return user;
};

const ProtectedRoute = ({ allowedRoles }: any) => {
    const auth = useAuth();
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const { user, isAuthenticated } = useSelector((state: RootState) => state.auth);
    // const { type } = useParams();
    const type = location.pathname.split('/')[1];


    const { mutate, isLoading, isError } = useMutation(getProfile, {
        onSuccess: (response) => {
            if (response?.statusCode === 200) {
                const token = localStorage.getItem("authToken") || "";
                const userData = {
                    user: response.data.user,
                    token: token,
                };
                dispatch(signin(userData));
            }
        },
        onError: (error: any) => {
            console.error("Error fetching profile:", error?.response?.data?.message);
        },
    });

    const checkRole = () => {
        if(allowedRoles.includes(user?.roleName)){
            return user?.roleName;
        }
    }

    useEffect(() => {
        if (auth && !isAuthenticated) {
            mutate({});
        }
    }, [auth, isAuthenticated, mutate]);


    if (!auth) {
        return <Navigate to={`/${type}/login`} replace />;
    }

    if (isLoading || !isAuthenticated) {
        return <div className="no-data-found">
            <Spin />
        </div>; 
    }
    if((type === 'user' ? checkRole() : type) !== user?.roleName){
        return <Navigate to={`/${type}/unauthorized`} replace />;
    }
    if (allowedRoles && user?.roleName && !allowedRoles.includes(user?.roleName)) {
        return <Navigate to={`/${type}/unauthorized`} replace />;
    }
    return <Outlet />;
};

export default ProtectedRoute;
