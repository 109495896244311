import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Progress, Segmented, Spin } from "antd";
import SideMenuSubmission from "./components/SideMenuSubmission";
import StrategyForm from "./components/Forms/StrategyForm";
import JobCreationForm from "./components/Forms/JobCreationForm";
import ISICMappingForm from "./components/Forms/ISICMappingForm";
import DocumentsForm from "./components/Forms/DocumentsForm";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useMutation } from "react-query";
import {
  getFormData,
  getSingleFormData,
  SubmitFormData,
  UpdateFormData,
} from "../../../services/FormSubmission";
import CustomAlert from "../../../components/common/CAlert";
import {
  addData,
  clearState,
  formSubmissionStatus,
  formSubmissionType,
  getNextFormName,
  getNextInitiativeFormName,
  getNextSubSectorFormName,
  getPreviousFormName,
  getPreviousInitiativeFormName,
  getPreviousSubSectorFormName,
  setNextFormName,
  updateFormData,
  updateInitiativeFormData,
  updateSubSectorFormData,
} from "../../../store/slices/dataSubmissionSlice";
import { Theme } from "../../../Theme";
import { RcFile } from "antd/es/upload";
import AlertModal from "../../../components/common/AlertModal";
import ConfigurationsForm from "./components/Forms/ConfigurationsForm";
import ISCOClassificationForm from "./components/Forms/ISCOClassificationForm";
import { CapFirstLetter } from "../../../utils/function";
import AlertsForm from "./components/Forms/AlertsForm";

interface Form {
  key: string;
  label: string;
  subheading: string;
  data: any[];
  name?: string;
  // initiatives?: any;
}

interface Data {
  [key: string]: {
    forms?: Form[];
    subSector?: any;
    initiatives?: any;
    configuration?: any;
  };
}

const AddDataSubmission: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [isSubmission, setIsSubmission] = useState(false);
  const [isMenuEnable, setIsMenuEnable] = useState(false);
  const [submissionId, setSubmissionId] = useState("");
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [modalType, setModalType] = useState('');
  const [alert, setAlert] = useState<{
    message: string;
    type: string;
    visible: boolean;
  }>({
    message: "",
    type: "success",
    visible: false,
  });
  const [loading, setLoading] = useState(false);
  const [selectedKey, setSelectedKey] = useState<string>(
    "stg_configuration_information"
  );
  const [isInitDataSave, setIsInitDataSave] = useState<boolean>(false);
  const [alignValue, setAlignValue] = React.useState<string>();
  const [configurationComplete, setConfigurationComplete] = useState(false);

  const entityType = useSelector((state: RootState) => state?.submission?.type);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const data: Data | null = useSelector(
    (state: RootState) => state?.submission?.data as Data
  );
  const configuration: any = useSelector(
    (state: RootState) => state?.submission?.configuration
  );
  const initialCopy: any = useSelector(
    (state: RootState) => state?.submission?.initiativeCopy
  );

  const nextFormName = useSelector(
    (state: RootState) => state.submission.nextFormName
  );
  const uploadData = useSelector(
    (state: RootState) => state.submission.uploadData
  );
  const isSubmit = useSelector((state: RootState) => state.submission.isSubmit);
  const submissionStatus = useSelector(
    (state: RootState) => state.submission.submissionStatus
  );
  const initUploadData = useSelector(
    (state: RootState) => state.submission.uploadInitiativeData
  );
  const filteredObj = Object.fromEntries(
    Object.entries(initUploadData).filter(
      ([key, value]) => alignValue && key.includes(alignValue?.toString())
    )
  );

  const [selectedForm, setSelectedForm] = useState(1);
  const progressPercentage = (selectedForm / 5) * 100;

  const currnetInitiativeFiles: any = filteredObj[alignValue as string];

  const configurationObj = {
    data: {
      submissionType: "",
      submissionName: "",
      breakDown: false,
      details: [],
    },
    name: "stg_configuration_information",
    heading: "Submission Details",
    subHeading: "Configure the settings of your submissions",
  };

  const location = useLocation();
  const { type, record } = location?.state || {};
  let isSubSector = record
    ? data?.[entityType]?.configuration?.data?.breakDown
    : configuration?.data?.breakDown;
  const initiatives = data?.[entityType]?.subSector;

  const getForms = () => {
    if (Object.keys(data).length === 0 || (!configurationComplete && !record)) {
      let forms = [];
      forms.push(record ? data?.[entityType]?.configuration : configurationObj);
      return forms;
    } else {
      if (isSubSector) {
        const sectors = data?.[entityType]?.subSector;
        const sectorIndex = sectors.findIndex(
          (item: any) =>
            item.value === (alignValue !== "" ? alignValue : sectors[0]?.value)
        );
        return [
          record ? data?.[entityType]?.configuration : configuration,
          ...(Array.isArray(sectors[sectorIndex]?.data)
            ? sectors[sectorIndex]?.data?.filter((k: any) => {
                return k.name != "Alerts";
              })
            : []),
        ];
      } else {
        const combineArray = [
          record ? data?.[entityType]?.configuration : configuration,
          ...(data?.[entityType]?.forms?.filter((k: any) => k.name !== "Alerts") || []),
        ];
        return combineArray;
      }
    }
  };

  const checkAlertsInSingleForm = () => {
    let alertAvailable: any;

    alertAvailable = data?.[entityType]?.forms?.filter((k) => {
      return k.name === "Alerts";
    });
    return alertAvailable?.length > 0;
  };

  const getOption = () => {
    let alertAvailable = false;
    const formattedOptions =
      data?.[entityType]?.subSector?.map((option: any) => {
        if (!alertAvailable) {
          alertAvailable = option?.data?.filter((k: any) => {
            return k.name == "Alerts";
          })[0];
        }

        return {
          value: option?.value,
          label: option?.name,
          heading: option?.heading,
        };
      }) || [];
    return alertAvailable
      ? [...formattedOptions, { value: "alerts", label: "Review Submission" }]
      : formattedOptions;
  };

  const singleFormSegmentOption = [
    {
      value: entityType as string,
      label: CapFirstLetter(
        data?.[entityType]?.configuration?.data?.submissionName
      ),
    },
    { value: "alerts", label: "Review Submission" },
  ];


  useEffect(() => {
    if (isSubSector && !configurationComplete) {
      if (nextFormName === null) {
        const selectedIndex = initiatives?.findIndex(
          (item: any) => item.value === alignValue
        );
        setSelectedKey(initiatives[selectedIndex]?.data[0]?.name);
      } else {
        setSelectedKey(nextFormName);
      }
    } else if (!isSubSector && !configurationComplete) {
      if (nextFormName === null) {
        setSelectedKey("stg_configuration_information");
      } else {
        setSelectedKey(nextFormName);
      }
    }
  }, [data, nextFormName]);

  useEffect(() => {
    setSelectedForm(selectedIndex);
  }, [selectedKey]);

  useEffect(() => {
    if (entityType !== "VRPs") {
      // const value = data?.[entityType]?.forms || data?.[entityType]?.subSector?.forms;
      // if (value && Array.isArray(value) && value.length > 0) {
      if (nextFormName !== null) {
        setSelectedKey(nextFormName);
      }
      // }
    }
  }, [nextFormName]);

  useEffect(() => {
    if (initiatives?.length > 0 && !alignValue) {
      const firstInitiative = initiatives[0];
      setAlignValue(firstInitiative.value);
      // setSelectedKey(firstInitiative.data[0]?.name);
    }
  }, [initiatives]);

  const handleInitiativeChange = (initiativeName: string) => {
    if (initiativeName == "alerts") {
      setShowAlert(true);
      setAlignValue(initiativeName);
    } else {
      setShowAlert(false);
      setIsInitDataSave(true);
      setLoading(true);
      if (isSubSector) {
        const selectedIndex = initiatives.findIndex(
          (item: any) => item.value === initiativeName
        );
        setAlignValue(initiativeName);
        setSelectedKey(initiatives[selectedIndex]?.data[0]?.name);
      } else {
        setAlignValue(initiativeName);
        const name = data?.[entityType]?.forms || undefined;
        const value = name && name[0]?.name;
        setSelectedKey(value as string);
      }
      setIsInitDataSave(false);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  const { mutate, isLoading, isError, error } = useMutation(
    record ? getSingleFormData : getFormData,
    {
      onSuccess: (response) => {
        if (response?.statusCode === 200) {
          const userData = {
            data: response.data.jsonData,
            type: record
              ? Object.keys(response?.data?.jsonData)[0]
              : response.data.type === "giga project"
              ? "giga"
              : response.data.type,
            configuration: configurationObj,
            initiativeCopy:
              response.data.type == "strategy"
                ? response.data.jsonData?.strategy?.forms
                : response.data.jsonData?.giga?.forms,
          };
          dispatch(addData(userData));
        }
      },
      onError: (error: any) => {
        setAlert({
          message: error
            ? error?.response?.data?.message
            : t("An error occurred during login. Please try again."),
          type: "error",
          visible: true,
        });
      },
    }
  );

  const {
    mutate: submitForm,
    isLoading: submitLoading,
    isError: submitIsError,
    error: submitError,
  } = useMutation(
    type === "edit" ||
      submissionStatus === "Draft" ||
      submissionStatus === "Pending"
      ? UpdateFormData
      : SubmitFormData,
    {
      onSuccess: (response) => {
        if (response?.statusCode === 200) {
          if (response?.data?.status === "Draft" && isConfirmModalVisible) {
            //first time create api call
            setSubmissionId(response?.data?.id);
            const userData = {
              data: response.data.jsonData,
              type: String(entityType),
              configuration:
                response.data.jsonData?.[entityType]?.configuration,
              initiativeCopy: initialCopy,
            };
            dispatch(addData(userData));
            dispatch(
              formSubmissionType({
                type: false,
              })
            );
            if (entityType !== "VRPs") {
              if (response?.data?.jsonData?.[entityType]?.subSector) {
                dispatch(
                  getNextSubSectorFormName({
                    key: entityType,
                    sector_value: alignValue
                      ? alignValue
                      : response?.data?.jsonData?.[entityType]?.subSector[0]
                          ?.value,
                    currentIndex: -1,
                  })
                );
                setIsMenuEnable(false);
              } else {
                dispatch(
                  getNextFormName({
                    key: entityType as string,
                    currentIndex: -1,
                  })
                );
              }
            } else {
              dispatch(
                getNextInitiativeFormName({
                  initiative_name: alignValue as string,
                  currentIndex: selectedIndex,
                })
              );
            }
            setIsSubmission(false);
            dispatch(formSubmissionStatus({ status: response?.data?.status }));
            setIsConfirmModalVisible(false);
          } else if (response?.data?.status === "Draft" && isSubmit) {
            setAlert({
              message:
                "Submission Unsuccessful. One or more fields from your submission require updates.",
              type: "error",
              visible: true,
            });
            setSubmissionId(response?.data?.id);
            const userData = {
              data: response.data.jsonData,
              type: String(entityType),
              configuration:
                response.data.jsonData?.[entityType]?.configuration,
              initiativeCopy: initialCopy,
            };
            dispatch(addData(userData));
            if (entityType !== "VRPs") {
              if (response?.data?.jsonData?.[entityType]?.subSector) {
                dispatch(
                  getNextSubSectorFormName({
                    key: entityType,
                    sector_value: alignValue
                      ? alignValue
                      : response?.data?.jsonData?.[entityType]?.subSector[0]
                          ?.value,
                    currentIndex: -1,
                  })
                );
              } else {
                dispatch(
                  getNextFormName({
                    key: entityType as string,
                    currentIndex: -1,
                  })
                );
              }
            } else {
              dispatch(
                getNextInitiativeFormName({
                  initiative_name: alignValue as string,
                  currentIndex: selectedIndex,
                })
              );
            }

            setIsSubmission(false);
            dispatch(formSubmissionStatus({ status: response?.data?.status }));
            dispatch(
              formSubmissionType({
                type: false,
              })
            );
          } else if (response?.data?.status === "Pending" && isSubmit) {
            setSubmissionId(response?.data?.id);
            const userData = {
              data: response.data.jsonData,
              type: String(entityType),
              configuration:
                response.data.jsonData?.[entityType]?.configuration,
              initiativeCopy: initialCopy,
            };
            dispatch(addData(userData));
            dispatch(
              formSubmissionType({
                type: false,
              })
            );
            if (entityType !== "VRPs") {
              if (response?.data?.jsonData?.[entityType]?.subSector) {
                dispatch(
                  getNextSubSectorFormName({
                    key: entityType,
                    sector_value: alignValue
                      ? alignValue
                      : response?.data?.jsonData?.[entityType]?.subSector[0]
                          ?.value,
                    currentIndex: -1,
                  })
                );
                setAlignValue("alerts");
                setShowAlert(true);
              } else {
                dispatch(
                  getNextFormName({
                    key: entityType as string,
                    currentIndex: -1,
                  })
                );
                setAlignValue("alerts");
                setShowAlert(true);
              }
            } else {
              dispatch(
                getNextInitiativeFormName({
                  initiative_name: alignValue as string,
                  currentIndex: selectedIndex,
                })
              );
            }
            setIsSubmission(false);
            dispatch(formSubmissionStatus({ status: response?.data?.status }));
            setIsConfirmModalVisible(false);
          } else {
            if (isConfirmModalVisible === false) {
              navigate("/user/dataHistory");
            }
          }
        }
      },
      onError: (error: any) => {
        setAlert({
          message: error
            ? error?.response?.data?.message
            : t("An error occurred during submission. Please try again."),
          type: "error",
          visible: true,
        });
        isConfirmModalVisible
          ? handleCancelConfiguration()
          : setIsSubmission(false);
      },
    }
  );

  useEffect(() => {
    if (record) {
      mutate({ id: record?.id });
    }
  }, [entityType, record]);

  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);

  const handleMenuClick = (e: any) => {
    dispatch(setNextFormName(e.key));
    setSelectedKey(e.key);
  };

  const menuItems = Array.isArray(getForms())
    ? getForms()?.map((item: any, i: number) => ({
        key: item?.name,
        label: item?.heading,
        subheading: item?.subHeading,
        disabled: isMenuEnable && item?.name !== 'stg_configuration_information'
      }))
    : [];

  const getLabelDescription = () => {
    const selectedItem = menuItems.find(
      (item: any) => item.key === selectedKey
    );
    return selectedItem;
  };

  const selectedIndex = menuItems.findIndex(
    (item: any) => item.key === selectedKey
  );
  const forms = getForms();
  const formData = forms[selectedIndex] || null;

  const callSubmitHandler = (submit: boolean) => {
    const entity: any = entityType?.toLowerCase();
    const formData = new FormData();
    formData.append("jsonData", JSON.stringify(data));
    formData.append("userId", user?.id?.toString() || "");
    formData.append("entityId", user?.entityId?.toString() || "");
    formData.append("isSubmit", String(submit));
    formData.append("entityType", entity);
    if (
      record ||
      submissionStatus === "Draft" ||
      submissionStatus === "Pending"
    ) {
      formData.append("id", record ? record?.id : submissionId);
    }
    let binaryData = isSubSector ? currnetInitiativeFiles : uploadData;
    if (binaryData) {
      Object.entries(binaryData).forEach(([key, file]) => {
        if (file) {
          formData.append(key, file as RcFile);
        }
      });
    }
    if (isSubSector) {
      formData.append("active_index", String(alignValue));
    }
    submitForm(formData);
  };

  const handleStepBack = () => {
    if (entityType !== "VRPs") {
      if (isSubSector) {
        dispatch(
          getPreviousSubSectorFormName({
            key: entityType,
            sector_value: alignValue as string,
            currentIndex: selectedIndex - 1,
          })
        );
      } else {
        dispatch(
          getPreviousFormName({
            key: entityType as string,
            currentIndex: selectedIndex - 1,
          })
        );
      }
    } else {
      dispatch(
        getPreviousInitiativeFormName({
          initiative_name: alignValue as string,
          currentIndex: selectedIndex,
        })
      );
    }
  };

  const handleSubmitForm = (
    form: any,
    submit?: boolean,
    allowNext?: boolean
  ) => {
    if (entityType !== "VRPs") {
      if (form?.name === "stg_configuration_information") {
        const arr = data?.strategy?.subSector && [
          ...data?.strategy?.subSector.map((subSectorItem: any) => {
            // Find matching items in form.details
            const matchingItem = form?.data?.details?.find(
              (detail: any) => detail.value === subSectorItem.value
            );

            // If there is a matching item in details, update the name and heading
            if (matchingItem) {
              return {
                ...subSectorItem,
                name: matchingItem.name, // Update the name
                heading: matchingItem.heading, // Update the heading
              };
            }
            // If no match, return the original subSector item
            return subSectorItem;
          }),
          // Add unique items from form.details that don't exist in subSector
          ...form?.data?.details.filter(
            (item: any) =>
              !data?.strategy?.subSector.some(
                (subSectorItem: any) => subSectorItem.value === item.value
              )
          ),
        ];
        if (form?.data?.breakDown) {
          const subSector = data?.strategy?.subSector
            ? arr
            : form?.data?.details;
          const obj = {
            [entityType]: {
              subSector,
              configuration: form,
            },
          };
          const userData = {
            data: obj as any,
            type: entityType as string,
            configuration: form,
            initiativeCopy: initialCopy,
          };
          dispatch(addData(userData));
       
        } else {
          const obj = {
            [entityType]: {
              forms:
                Object.keys(data).length === 0
                  ? initialCopy
                  : data?.[entityType]?.forms,
              configuration: form,
            },
          };

          const userData = {
            data: obj as any,
            type: entityType as string,
            configuration: form,
            initiativeCopy: initialCopy,
          };

          dispatch(addData(userData));
          // dispatch(
          //   getNextFormName({
          //     key: keyType as string,
          //     currentIndex: -1,
          //   })
          // );
        }
        setModalType('continue');
        setIsConfirmModalVisible(true);
      } else {
        if (isSubSector) {
          dispatch(
            updateSubSectorFormData({
              key: entityType,
              sector_value: alignValue as string,
              name: form?.name,
              newForm: form,
            })
          );

          dispatch(
            getNextSubSectorFormName({
              key: entityType,
              sector_value: alignValue as string,
              currentIndex: selectedIndex - 1,
            })
          );
        } else {
          dispatch(
            updateFormData({
              key: entityType as string,
              name: form?.name,
              newForm: form,
            })
          );
          dispatch(
            getNextFormName({
              key: entityType as string,
              currentIndex: selectedIndex - 1,
            })
          );
        }
      }

      if (submit) {
        setModalType('submit');
        setIsSubmission(true);
      }
    } else {
      dispatch(
        updateInitiativeFormData({
          initiative_name: alignValue as string,
          name: form?.name,
          newForm: form,
        })
      );

      if (submit) {
        setModalType('submit');
        setIsSubmission(true);
      }

      if (allowNext) return;

      if (selectedIndex !== 3) {
        dispatch(
          getNextInitiativeFormName({
            initiative_name: alignValue as string,
            currentIndex: selectedIndex,
          })
        );
      }
    }
  };

  const handleDraftForm = (form: any) => {

    if (entityType !== "VRPs") {
      dispatch(
        updateFormData({
          key: entityType as string,
          name: form?.name,
          newForm: form,
        })
      );
  
    } else {
      dispatch(
        updateInitiativeFormData({
          initiative_name: alignValue as string,
          name: form?.name,
          newForm: form,
        })
      ); 
    }
    setModalType('draft');
    setIsSubmission(true);
  };

  const handleGetJsonData = (type: any) => {
    if (record) {
      mutate({ id: record?.id });
    }else{
      mutate({ type });
    }
  };

  const handleChangeConfiguration = () => {
    setConfigurationComplete(true);
  };

  const handleRemoveAlignValue = () => {
    setAlignValue("");
  };

  const handleDisableMenu = (key:boolean) => {
    setIsMenuEnable(key);
  }

  const renderForm = () => {
    if (!forms || forms.length === 0) return null;
    switch (selectedKey) {
      case forms[0]?.name:
        return (
          <ConfigurationsForm
            data={formData}
            handleSubmitForm={handleSubmitForm}
            handleGetJsonData={handleGetJsonData}
            handleChangeConfiguration={handleChangeConfiguration}
            setAlert={setAlert}
            handleRemoveAlignValue={handleRemoveAlignValue}
            handleDisableMenu={handleDisableMenu}
          />
        );
      case forms[1]?.name:
        return (
          <StrategyForm
            data={formData}
            handleSubmitForm={handleSubmitForm}
            handleDraftForm={handleDraftForm}
            handleStepBack={handleStepBack}
            isInitDataSave={isInitDataSave}
          />
        );
      case forms[2]?.name:
        return (
          <JobCreationForm
            data={formData}
            handleSubmitForm={handleSubmitForm}
            handleDraftForm={handleDraftForm}
            handleStepBack={handleStepBack}
            setAlert={setAlert}
          />
        );
      case forms[3]?.name:
        return (
          <ISICMappingForm
            data={formData}
            handleSubmitForm={handleSubmitForm}
            handleDraftForm={handleDraftForm}
            handleStepBack={handleStepBack}
            initiatives={initiatives}
            alignValue={alignValue}
            selectedKey={selectedKey}
            submissionData={data}
          />
        );
      case forms[4]?.name:
        return (
          <ISCOClassificationForm
            data={formData}
            handleSubmitForm={handleSubmitForm}
            handleDraftForm={handleDraftForm}
            handleStepBack={handleStepBack}
            initiatives={initiatives}
            alignValue={alignValue}
            selectedKey={selectedKey}
            submissionData={data}
          />
        );
      case forms[5]?.name:
        return (
          <DocumentsForm
            data={formData}
            handleSubmitForm={handleSubmitForm}
            handleDraftForm={handleDraftForm}
            handleStepBack={handleStepBack}
            initiativeName={alignValue}
          />
        );
      default:
        return null;
    }
  };

  const handleCancelConfiguration = () => {
    setIsConfirmModalVisible(false);
    setConfigurationComplete(false);
  };

  const shouldShowReviewHeader =
    (checkAlertsInSingleForm() &&
      selectedKey !== "stg_configuration_information") ||
    (isSubSector && selectedKey !== "stg_configuration_information");

  const getModalMessage = () => {
    if (modalType === 'continue') {
      return "Are you sure you want to continue?";
    } else if (modalType === 'submit') {
      return "Are you sure you want to submit this form?";
    } else if (modalType === 'draft') {
      return "Are you sure you want to save this form as a draft?";
    }
    return "";
  };

  return (
    <div
      className={
        showAlert
          ? "submission-alert-content-div h-100"
          : "submission-content-div h-100"
      }
    >
      {!showAlert && (
        <div className="submission-form-menu p-3">
          <SideMenuSubmission
            items={menuItems}
            selectedKey={selectedKey}
            onClick={handleMenuClick}
            // disabled={isMenuEnable}
          />
        </div>
      )}
      <div>
        {shouldShowReviewHeader && (
          <div
            className="header-initiative-div"
            style={{ height: "10vh", paddingLeft: "20px" }}
          >
            <Segmented
              value={alignValue || (isSubSector ? getOption()[0]?.value : singleFormSegmentOption[0]?.value)}
              style={{ marginBottom: 8 }}
              onChange={(value) => handleInitiativeChange(value as string)}
              className="mb-0"
              options={isSubSector ? getOption() : singleFormSegmentOption}
            />
            <div className="add-initiative-div">
              <p className="m-0 break-down-description">Type</p>
              <span className="forgot-password">
                {CapFirstLetter(
                  entityType === "giga" ? "Giga Project" : entityType
                )}
              </span>
            </div>
          </div>
        )}
        <div
          className={
            shouldShowReviewHeader
              ? "round-content-div pb-0 p-4 mt-2"
              : "round-content-div pb-0 p-4"
          }
          style={{
            height: shouldShowReviewHeader ? "73vh" : "85vh",
          }}
        >
          {!showAlert ? (
            (isLoading && forms?.length === 0) || loading ? (
              <div className="no-data-found h-100">
                <Spin />
              </div>
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <p className="submission-form-heading m-0">
                      {getLabelDescription()?.label}
                    </p>
                    {selectedIndex !== 0 && (
                      <div className="mx-3 mb-2" style={{ width: 120 }}>
                        <p className="m-0 mb-0 break-down-description">{`Form ${selectedIndex} of ${
                          menuItems?.length - 1
                        }`}</p>
                        <Progress
                          percent={progressPercentage}
                          format={(percent: any) => `${percent.toFixed(0)}%`}
                          showInfo={false}
                          strokeColor={Theme.colors.themeSecondary}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <p className="sub-desc-submission">
                  {getLabelDescription()?.subheading}
                </p>
                {renderForm()}
              </>
            )
          ) : (
            <AlertsForm
              handleSubmitForm={handleSubmitForm}
              handleDraftForm={handleDraftForm}
              handleStepBack={handleStepBack}
            />
          )}
        </div>
      </div>
      <AlertModal
        visible={isConfirmModalVisible || isSubmission}
        onCancel={() =>
          isConfirmModalVisible
            ? handleCancelConfiguration()
            : setIsSubmission(false)
        }
        onConfirm={
          isConfirmModalVisible
            ? () => callSubmitHandler(false)
            : () => callSubmitHandler(isSubmit ? true : false)
        }
        message={getModalMessage()}
        loading={submitLoading}
      />

      <CustomAlert
        message={alert.message}
        type={alert.type as "success" | "info" | "warning" | "error"}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={6000}
      />
    </div>
  );
};

export default AddDataSubmission;
