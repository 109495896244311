// api.ts
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Ensure this environment variable is set correctly
  headers: {
    'Content-Type': 'application/json', // Default content type
  },
});


api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers['authorization'] =  token
    }
      // Set content type only if it's not FormData
      if (!(config.data instanceof FormData)) {
        config.headers['Content-Type'] = 'application/json';
      }
    return config
  },
  error => {
    Promise.reject(error)
  }
)


api.interceptors.response.use(
  response => {
    return response
  },
  function (error) {
    // const originalRequest = error.config
    if (
      error.response.status === 401 ) {
        localStorage.removeItem('authToken');
        window.location.href = '/';
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

export default api;
