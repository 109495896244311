import React, { useState } from "react";
import { Upload, UploadProps } from "antd";
import { useTranslation } from "react-i18next";
import CInput from "../../../../../components/common/CInput";
import CButton from "../../../../../components/common/CButton";
import { Theme } from "../../../../../Theme";
import { toCamelCase } from "../../../../../utils/function";
import { camelCase } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import {
  formSubmissionType,
  updateInitiativeUploadData,
  updateSubSectorUploadData,
  updateUploadData,
} from "../../../../../store/slices/dataSubmissionSlice";
import { RcFile } from "antd/es/upload";

interface DocumentProps {
  data: any;
  handleSubmitForm: (form: any, isSubmit?: boolean) => void;
  handleDraftForm: (form: any) => void;
  handleStepBack: () => void;
  initiativeName?: string;
}


interface Data {
  [key: string]: {
    forms?: any[];
    subSector?: any;
    initiatives?: any;
    configuration?: any;
  };
}


const DocumentsForm: React.FC<DocumentProps> = ({
  data,
  handleSubmitForm,
  handleDraftForm,
  handleStepBack,
  initiativeName,
}) => {
  const [formData, setFormData] = useState(data);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputData = formData?.data[0];
  const tableData = formData?.data[1];
  const entity = useSelector((state: RootState) => state?.auth?.user?.entity);
  const uploadData = useSelector(
    (state: RootState) => state.submission.uploadData
  );
  const initUploadData = useSelector(
    (state: RootState) => state.submission.uploadInitiativeData
  );
  const entityType = useSelector(
    (state: RootState) => state?.submission?.type
  );
  const submissionData: Data | null = useSelector(
    (state: RootState) => state?.submission?.data as Data
  );

  const filteredObj = Object.fromEntries(
    Object.entries(initUploadData).filter(
      ([key, value]) =>
        initiativeName && key.includes(initiativeName?.toString())
    )
  );

  const handleSubmit = () => {
    dispatch(
      formSubmissionType({
        type: true,
      })
    );

    handleSubmitForm(formData, true);
  };

  const handleCommentChange = (
    value: string,
    commentName: string,
    typeName: string
  ) => {
    const updatedFormData = {
      ...formData,
      data: formData.data.map((item: any) => {
        if (item.data) {
          return {
            ...item,
            data: item.data.map((subItem: any) => {
              if (subItem.comments && subItem.comments.name === commentName) {
                return {
                  ...subItem,
                  comments: {
                    ...subItem.comments,
                    value,
                  },
                };
              }
              return subItem;
            }),
          };
        }
        return item;
      }),
    };

    setFormData(updatedFormData);
  };

  const onchangeFile = (info: any, type: string) => {
    const { file } = info;
    const newFile = file.originFileObj || file;
    if (entityType !== "VRPs") {
      if(submissionData?.[entityType]?.forms){
      dispatch(updateUploadData({ type, file: newFile as RcFile }));
    } else {
      dispatch(
        updateSubSectorUploadData({
          initiative: initiativeName as string,
          key: type,
          file: newFile as RcFile,
        })
      );
    }
  }
  };

  return (
    <div className="w-100">
      <div className="isis-mapping-detail d-flex align-items-center mt-3">
        <span className="mx-3 job-count">{inputData?.description}</span>
        <CInput
          className="mx-3 w-100"
          placeholder={inputData?.placeHolder}
          type={inputData?.dataType}
          value={entity?.name}
          disabled={true}
        />
      </div>

      <div className="supporting-docs-table mt-3">
        <div className="mapping-level d-flex align-items-center justify-content-center">
          <span className="click-drag">{tableData?.columns.type}</span>
        </div>
        <div className="mapping-level d-flex align-items-center justify-content-center">
          <span className="click-drag">{tableData?.columns.requirements}</span>
        </div>
        <div className="mapping-level d-flex align-items-center justify-content-center">
          <span className="click-drag">
            {tableData?.columns.evidenceAttached}
          </span>
        </div>
        <div className="mapping-level d-flex align-items-center justify-content-center">
          <span className="click-drag">{tableData?.columns.comments}</span>
        </div>
      </div>

      {tableData?.data?.map((item: any, index: number) => (
        <div className="supporting-docs-table-row mt-2" key={index}>
          <div className="row-level d-flex align-items-center justify-content-center p-2">
            <span className="supporting-column-one">{item?.type}</span>
          </div>
          <div className="row-level d-flex align-items-center justify-content-center p-2">
            <span
              className="supporting-column-two"
              dangerouslySetInnerHTML={{ __html: item?.requirements }}
            />
          </div>
          <Upload
            onChange={(info) =>
              onchangeFile(info, item?.evidenceAttached?.name)
            }
            className="upload-docs-submission row-level"
          >
            <div className="d-flex align-items-center justify-content-center p-2 cursor-pointer">
              <img
                src={Theme.icons.icn_upload_evidence}
                style={{ height: "16px" }}
                alt="Upload Icon"
              />
              {(() => {
                const evidence = item?.evidenceAttached;
                const val: any = filteredObj[initiativeName as string];
                  const uploadedFile =
                  submissionData?.[entityType]?.subSector
                      ?(val !== undefined && val[evidence?.name])
                      : uploadData?.[evidence?.name];
                  const fileName =
                    uploadedFile?.name !== undefined
                      ? uploadedFile?.name
                      : evidence?.value;

                  return fileName ? (
                    <div className="upload-docs-submission row-level">
                      <span className="mx-2 no-of-jobs file-name-with-ellipsis">
                        {fileName}
                      </span>
                    </div>
                  ) : (
                    <span className="mx-2 no-of-jobs">{t("Upload")}</span>
                  );
                // }
              })()}
            </div>
          </Upload>

          <div className="row-level d-flex align-items-center p-3">
            <CInput
              placeholder={item?.comments?.placeHolder}
              className="w-100 comment-input row-level"
              type={item?.comments?.dataType}
              value={item?.comments?.value}
              onChange={(e) =>
                handleCommentChange(
                  e.target.value,
                  item?.comments?.name,
                  item?.name
                )
              }
            />
          </div>
        </div>
      ))}

<div className="form-bottom-div d-flex justify-content-between">
        <CButton className="floppy-btn"  
            onClick={() => handleDraftForm(formData)}>
          <img src={Theme.icons.icn_floppy_disk} />
          {t("Save & Continue Later")}
        </CButton>
        <div className="form-footer-div">
          <CButton
            className="save-continue-btn w-75 mx-2"
            onClick={() => handleStepBack()}
          >
            <img src={Theme.icons.icn_arrow_left_blue} />
            {t("Back")}
          </CButton>
          <CButton
            className="next-btn"
            onClick={() => handleSubmit()}
          >
            {t("Submit")}
            <img src={Theme.icons.icn_arrow_right_white} />
          </CButton>
        </div>
      </div>
    </div>
  );
};

export default DocumentsForm;
