import React, { ComponentType, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Login from './pages/Auth/Login';
import PortalScreen from './pages/Layout/PortalScreen';
// import i18n from './i18next';
import EntityOverview from './pages/Admin/Entity/EntityOverview';
import Otp from './pages/Auth/Otp';
import RegistrationComplete from './pages/Auth/RegistrationComplete';
import Register from './pages/Auth/Register';
import Forgot from './pages/Auth/Forgot';
import Reset from './pages/Auth/Reset';
import CreateEntity from './pages/Admin/Entity/CreateEntity';
import UserListing from './pages/Users/User/User';
import CreateUser from './pages/Users/User/CreateUser';
import { useSelector } from 'react-redux';
import { RootState } from './store/store';
import { useTranslation } from 'react-i18next';
import UserRequest from './pages/Admin/Request/UserRequest';
import ViewRequest from './pages/Admin/Request/ViewRequest';
import DataSubmissionHistory from './pages/Users/DataSubmission/DataSubmissionHistory';
import AddDataSubmission from './pages/Users/DataSubmission/AddDataSubmission';
import ProtectedRoute from './config/ProtectedRoutes';
import { Theme } from './Theme';


interface ProtectedRouteProps {
  element: ComponentType<any>; // Type for a React component
  [key: string]: any; // Allow additional props
}


function App() {
  const { t, i18n } = useTranslation();
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  const location = useLocation();


  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
    const isRTL = currentLanguage === 'ar';
    document.documentElement.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
  }, [currentLanguage, i18n]);


  return (
    <>
      <Routes>

        <Route path="/" element={<Navigate to="admin/login" />} />

        <Route path=':type/login' element={<Login />} />
        <Route path='forgot' element={<Forgot />} />
        <Route path='reset' element={<Reset />} />
        <Route path='register' element={<Register />} />
        <Route path='otp' element={<Otp />} />
        <Route path='registrationComplete' element={<RegistrationComplete />} />

        <Route element={<ProtectedRoute allowedRoles={["admin"]} />} >
          <Route path=':type' element={<PortalScreen />}>
            <Route path='dashboard/entities' element={<EntityOverview />} />
            <Route path='entity/:type' element={<CreateEntity />} />
            <Route path='usersRequest' element={<UserRequest />} />
            <Route path='viewUserRequest' element={<ViewRequest />} />
          </Route>
        </Route>

        <Route element={<ProtectedRoute allowedRoles={["primary_spoc", "standard_user"]} />} >
          <Route path=':type' element={<PortalScreen />}>
            <Route path='dashboard/users' element={<UserListing />} />
            <Route path='users/:type' element={<CreateUser />} />
            <Route path='dataHistory' element={<DataSubmissionHistory />} />
            <Route path='submission/:type' element={<AddDataSubmission />} />
          </Route>
        </Route>
        <Route path="*" element={<div className="no-data-found"><h1 className="t-center" style={{color: Theme.colors.white}}>Not Found</h1></div>}></Route>
      </Routes>
    </>


  );
}

export default App;
