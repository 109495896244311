import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Theme } from "../../../../Theme";
import CInput from "../../../../components/common/CInput";
import CButton from "../../../../components/common/CButton";
import AlertModal from "../../../../components/common/AlertModal";
import PhoneInput from "react-phone-input-2";
import { Upload } from "antd";
import CustomSelect from "../../../../components/common/CSelect";
import { CapFirstLetter } from "../../../../utils/function";
import { getAllVrps } from "../../../../services/FormSubmission";
import { useMutation } from "react-query";

interface EntityCardProps {
  data: any;
  open: boolean;
  onToggle: () => void;
  recordIndex: number;
  handleInputChange: (value: string, index: number, fieldName: string) => void;
  removeEntityHandler: (index: number) => void;
  onchangeFile: (info: any, index: number) => void;
  onSubmissionChange: (
    value: string,
    recordIndex: number,
    i: number,
    fieldName: string
  ) => void;
  getFourDigitName: (
    value: string,
    recordIndex: number,
    i: number,
    fieldName: string
  ) => void;
  totalLength: number;
  AddNewSubmission: (index: number) => void;
  handleRemoveSubmission: (recordIndex: number, i: number) => void;
}

const EntityCard: React.FC<EntityCardProps> = ({
  data,
  recordIndex,
  open,
  onToggle,
  removeEntityHandler,
  totalLength,
  AddNewSubmission,
  handleInputChange,
  onchangeFile,
  onSubmissionChange,
  getFourDigitName,
  handleRemoveSubmission,
}) => {
  const { Dragger } = Upload;
  const [isVisible, setIsVisible] = useState(false);
  const [indexNumber, setIndexNumber] = useState(0);
  const [emailError, setEmailError] = useState<string>("");
  const [vrps, setVrps] = useState([]);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const location = useLocation();
  const { t } = useTranslation();
  const [spocRequired, setSpocRequired] = useState(false);
  const type = location?.pathname?.split("/")[3];

  const validateEmail = (value: string) => {
    return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
  };

  const handleDiscardRecord = (index: number) => {
    setIndexNumber(index);
    setIsVisible(true);
  };

  const EntityType = [
    { value: "giga", label: "Giga Project" },
    { value: "vprs", label: "VRPs" },
    { value: "strategy", label: "Strategy" },
  ];

  const removeHandler = (index: number) => {
    removeEntityHandler(index);
    setIsVisible(false);
  };

  const transformDetails = (details: any) => {
    const groupedDetails: { [key: string]: string[] } = {};

    details.forEach((detail: any) => {
      if (!groupedDetails[detail.type]) {
        groupedDetails[detail.type] = [];
      }
      groupedDetails[detail.type].push(detail.value);
    });
    return Object.keys(groupedDetails).map((type) => ({
      type,
      values: groupedDetails[type],
    }));
  };

  useEffect(() => {
    if (
      data?.spocName !== "" ||
      data?.spocEmail !== "" ||
      data?.mobileNumber !== ""
    ) {
      setSpocRequired(true);
    } else {
      setSpocRequired(false);
      setEmailError("");
    }
  }, [data]);

      const { mutate, isLoading, isError, error } = useMutation(getAllVrps ,
        {
          onSuccess: (response) => {
            if (response?.statusCode === 200) {
           setVrps(response?.data);
            }
          },
          onError: (error: any) => {
            // setAlert({
            //   message: error
            //     ? error?.response?.data?.message
            //     : t("An error occurred during login. Please try again."),
            //   type: "error",
            //   visible: true,
            // });
          },
        }
      );

      const getData = (val: any) => {

        if (!val) return []; // Return an empty array if data is undefined
        return val.map((x: any) => ({
            value: x.name,
            label: x.name 
        }));
    };
  

  useEffect(() => {
mutate({});
  },[])

  const handlevalidateEmail = (value: string, index: number, field: string) => {
      // Perform email validation
      if (!emailRegex.test(value)) {
        setEmailError("Please enter a valid email address.");
      } else {
        setEmailError(""); // Clear error if valid
      }
      handleInputChange(value, index, "spocEmail")
  }

  let entityCounts = transformDetails(data?.details);
  return (
    <>
      {!open ? (
        <div
          className="d-flex justify-content-between align-items-center px-3 mt-2 entity-user-row cursor-pointer"
          onClick={onToggle}
        >
          <p className="m-0 entity-user-heading">
            {data?.name ? CapFirstLetter(data?.name) : "Entity Name"}
          </p>
          <div className="d-flex align-items-center">
            <img src={Theme?.icons?.icn_entity_user} />
            <span className="entity-user-name mx-1">{CapFirstLetter(data?.spocName)}</span>
            <span className="support-bulk mx-1">
              <span style={{ color: "grey" }}>|</span>{" "}
              {data?.spocName === "" && data?.spocEmail === ""
                ? "SPOC not assigned yet"
                : data?.spocEmail}
            </span>
          </div>

          <div className="d-flex align-items-center">
            {entityCounts?.map((item) =>
              item?.type !== "" ? (
                <div className="entity-tag px-2 d-flex align-items-center mx-1">
                  <span className="entity-tag-title">{item?.type === 'vprs' ? 'VRPs' : item?.type === 'giga' ? 'Giga Project' : CapFirstLetter(item?.type)}</span>
                  <div className="no-of-entity-tag mx-1">
                    <span>{item?.values?.length}</span>
                  </div>
                </div>
              ) : null
            )}
          </div>

          <CButton className="border-btn">Modify</CButton>
        </div>
      ) : (
        <div
          className="isis-mapping-detail mt-3"
          style={type === 'add' ? {  backgroundColor: "#f8fafc" } : {  backgroundColor: "none", borderStyle:'none', padding:'0' }}
        >
          <div className={`d-flex justify-content-between align-items-center ${type !== 'add' && 'd-none'}`}>
            <span className="list-heading m-0 sf-bold">
              {t("Entity Details")}
            </span>

            <div className="d-flex justify-content-end align-items-center">
              {totalLength !== 1 && (
                <CButton
                  className="back-btn mx-2"
                  style={{ width: "100px", height: "40px" }}
                  onClick={() => handleDiscardRecord(recordIndex)}
                >
                  {t("Discard")}
                </CButton>
              )}
              <CButton
                className="next-btn"
                onClick={onToggle}
                style={{ width: "100px", height: "40px" }}
              >
                {t("Done")}
              </CButton>
            </div>
          </div>
          <p className="spoc-assignment-proof mt-2">{t("Information")}</p>

          <div className="row">
            <div className="col-lg-3">
              <CInput
                label={t("Entity Name")}
                value={data?.name}
                placeholder={t("Enter Entity Name")}
                required={true}
                disabled={type !== 'add'}
                onChange={(e) =>
                  handleInputChange(e.target.value, recordIndex, "name")
                }
              />
            </div>
            <div className="col-lg-3">
              <CInput
                label={t("SPOC Name")}
                value={data?.spocName}
                placeholder={t("Enter SPOC Name")}
                required={spocRequired}
                disabled={type !== 'add'}
                onChange={(e) =>
                  handleInputChange(e.target.value, recordIndex, "spocName")
                }
              />
            </div>

            <div className="col-lg-3">
              <CInput
                label={t("SPOC Email Address")}
                value={data?.spocEmail}
                placeholder={t("Enter SPOC Email Address")}
                required={spocRequired}
                disabled={type !== 'add'}
                onChange={(e) =>
                  handlevalidateEmail(e.target.value, recordIndex, "spocEmail")
                  // handleInputChange(e.target.value, recordIndex, "spocEmail")
                }
              />
              {emailError && <div className="sf-regular" style={{ color: "red", fontSize:'13px' }}>{emailError}</div>}
            </div>
            <div className="col-lg-3">
              <label
                className="inputfield-label"
                style={{ display: "block", marginBottom: "5px" }}
              >
                {t("Mobile Number")}
                {spocRequired && <span style={{ color: "red" }}>*</span>}
              </label>
              <PhoneInput
                country={"sa"}
                onlyCountries={["sa"]}
                placeholder={t("Enter Mobile Number")}
                value={data?.mobileNumber}
                onChange={(phone) =>
                  handleInputChange(phone, recordIndex, "mobileNumber")
                }
                disabled={type !== 'add'}
              />
            </div>
          </div>

          <p className="spoc-assignment-proof mt-2">
            Add SPOC Assignment Proof
          </p>

          <div
            className="mapping-level w-100 p-2 d-flex align-items-center justify-content-between"
            style={ type === 'add' ? { backgroundColor: Theme?.colors?.white } : {  backgroundColor: "#f8fafc" }}
          >
            <div className="d-flex flex-column">
              <span className="mx-3 upload-proof sf-semibold">
                {t(
                  "Upload the proof of the assignment of SPOC for this entity."
                )}
              </span>
              <span className="mx-3 level-sector-description sf-regular">
                {t(
                  "(If you do not have the document please contact the focal person for onboarding at National Labour Observatory)"
                )}
              </span>
            </div>
            <Dragger
            disabled={type !== 'add'}
              onChange={(info) => onchangeFile(info, recordIndex)}
              className="upload-docs-submission row-level"
            >
              <div className="d-flex align-items-center p-2 upload-bulk-div">
                <img
                  src={Theme.icons.icn_blue_upload}
                  style={{ height: "18px", fill: "red" }}
                />
                {data?.fileName === "" || data?.fileName === null ? (
                  <div className="mx-2 text-start">
                    <p className="m-0 upload-bulk-description">
                      Click or drag file to this area to upload
                    </p>
                    <p className="m-0 upload-bulk-description">
                      Support for a single or bulk upload.
                    </p>
                  </div>
                ) : (
                  <p className="m-0 mx-2 upload-bulk-description">
                    {data?.fileName}
                  </p>
                )}
              </div>
            </Dragger>
          </div>

          <p className="spoc-assignment-proof mt-3">
            {t("Submission Details")}
          </p>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "3fr 16fr 1fr",
              gridGap: "10px",
            }}
          >
            <span className="submission-form-type">{t("Submission Type")}</span>
            <span className="submission-form-type">{t("Submission Name")}</span>
          </div>
          {data?.details?.map((item: any, i: number) => {
            return (
              <div
                key={i}
                style={{
                  display: "grid",
                  gridTemplateColumns: "3fr 16fr 1fr",
                  gridGap: "10px",
                }}
                className="mt-2"
              >
                <CustomSelect
                  options={EntityType}
                  required={true}
                  value={item?.type ? item?.type : undefined}
                  onChange={(value) =>
                    onSubmissionChange(value, recordIndex, i, "type")
                  }
                  placeholder={t("Select Entity Type")}
                  disabled={type === "view"}
                />
               { item?.type === 'vprs' ?
                   <CustomSelect
                   options={getData(vrps)}
                  //  required={true}
                  value={item?.value ? item.value : undefined}
                   onChange={(value) =>
                     onSubmissionChange(value, recordIndex, i, "value")
                   }
                   placeholder={t("Select VRPs name")}
                   showSearch={true}
                   hideValue={true}
                   disabled={type === "view"}
                 />
               : <CInput
                // defaultValue={item?.value}
                  placeholder={
                    item?.type === "giga" || item?.type === "strategy"
                      ? `Type name of the ${item?.type}`
                      : "please enter the value"
                  }
                  value={item?.value}
                  onChange={(e) =>
                       onSubmissionChange(
                          e.target.value,
                          recordIndex,
                          i,
                          "value"
                        )
                  }
                  disabled={type === "view"}
                />}

                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ backgroundColor: "#FEF2F2", borderRadius: "8px" }}
                  onClick={() =>
                    data?.details?.length !== 1 && type !=='view' &&
                    handleRemoveSubmission(recordIndex, i)
                  }
                >
                  <img src={Theme?.icons?.icn_delete} />
                </div>
              </div>
            );
          })}

          <div className={type ==='view' ? 'd-none' : "mt-3 d-flex justify-content-end"}>
            <CButton
              className="add-new-year"
              onClick={() => AddNewSubmission(recordIndex)}
            >
              <img src={Theme.icons.icn_plus} />
              <span>{t("Add New")}</span>
            </CButton>
          </div>
        </div>
      )}

      <AlertModal
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        onConfirm={() => removeHandler(indexNumber)}
        message="Are you Sure! You want to discard the entity"
      />
    </>
  );
};

export default EntityCard;
