import instance from "./api/api_instance";

export const getSubmissionList = async (credentials:any) => {
    const { pageNumber, pageSize, ...rest } = credentials;
    const response = await instance.post('submission/list', rest, {
        params: { page: pageNumber, per_page: pageSize },
    });
    return response.data;
};

export const getFormData = async (credentials: object) => {
    const response = await instance.post('common/get-json-template', credentials);
    return response.data;
  };

  export const getAllActivityLevels = async (credentials: object) => {
    const response = await instance.post('common/allActivityLevels', credentials);
    return response.data;
  };

  export const getAllVrps = async (credentials: object) => {
    const response = await instance.post('common/all-vrps', credentials);
    return response.data;
  };

export const getSingleFormData = async (credentials: object) => {
    const response = await instance.post('submission/get-submission', credentials);
    return response.data;
  };

  export const getEntityName = async (credentials: object) => {
    const response = await instance.post('/entity', credentials);
    return response.data;
  };

  export const SubmitFormData = async (formData: FormData) => {
    const response = await instance.post('submission/create', formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    return response.data;
  };

  export const UpdateFormData = async (formData: FormData) => {
    const response = await instance.post('submission/update-submission', formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    return response.data;
  };

  export const getFourDigitInitiativeCode = async (credentials: object) => {
    const response = await instance.post('common/search-codes', credentials);
    return response.data;
  };