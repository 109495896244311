import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Menu, MenuProps } from "antd";

interface SideMenuProps {
  items: MenuProps["items"];
  selectedKey: string;
  onClick: (e: any) => void;
  disabled?: boolean;
}

const SideMenuSubmission: React.FC<SideMenuProps> = ({
  selectedKey,
  onClick,
  items,
  disabled
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Menu
      // disabled={disabled}
        mode="inline"
        className="mt-4"
        selectedKeys={[selectedKey]}
        onClick={onClick}
        items={items}
      />
    </>
  );
};

export default SideMenuSubmission;
