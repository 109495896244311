import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CInput from "../../../../../components/common/CInput";
import CButton from "../../../../../components/common/CButton";
import CustomSelect from "../../../../../components/common/CSelect";
import instance from "../../../../../services/api/api_instance";
import AlertModal from "../../../../../components/common/AlertModal";
import { useDispatch, useSelector } from "react-redux";
import { setLevelDropdownData } from "../../../../../store/slices/dataSubmissionSlice";
import { RootState } from "../../../../../store/store";
import { Theme } from "../../../../../Theme";

interface ISICMappingCardProps {
    data: any;
    open: boolean;
    onToggle: () => void;
    recordIndex: number;
    handleInputChange: (value: string, index: number) => void;
    removeISICHandler: (index: number) => void;
    handleLevelChange: (value: string, index: number, recordIndex: number, dropdown:any) => void;
    totalLength: number;
}


const ISICMappingCard: React.FC<ISICMappingCardProps> = ({ data, recordIndex, open, onToggle, handleInputChange, removeISICHandler, handleLevelChange, totalLength }) => {
    const { noOfJobs, levels } = data || {};
    const [isVisible, setIsVisible] = useState(false);
    const [indexNumber, setIndexNumber] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const reduxDropdownData = useSelector((state: RootState) => state.submission.levelsDropdownData);
    const submissionStatus = useSelector((state: RootState) => state.submission.submissionStatus);
    const type = location?.pathname?.split('/')[3];
    const isEditable = type === 'edit' || submissionStatus === 'Draft' || submissionStatus === 'Pending';
    const [dropdownData, setDropdownData] = useState(
        levels?.map((item: any, index: number) => ({
            value: isEditable ? item?.value : "",
            options: isEditable ? item?.options : [],
            isEnabled: isEditable ? true : index === 0,
        }))
    );

    const handleDiscardRecord = (index:number) => {
        setIndexNumber(index);
        setIsVisible(true);  
    }


    const fetchOptions = async (levelIndex: number, filterKey?: string, sectionValue?: string) => {
        try {
            const currentLevel = levels[levelIndex];
            const requestBody = {
                [`level_${levelIndex + 1}`]: true,
                ...(filterKey && { [filterKey]: sectionValue }),
            };

            const response = await instance.post(currentLevel.endpoint,
                JSON.stringify(requestBody),
            );

            const formattedOptions = response?.data?.data?.list?.map((item: any) => {
                const filterKeyValue = filterKey && filterKey in item ? item[filterKey] : undefined;
                return {
                    ...item,
                    label: item.description,
                    value: levelIndex === 0 ? item?.section : levelIndex === 1 ? item?.division : levelIndex === 2 ? item?.groupName : levelIndex === 3 ? item?.class : levelIndex === 4 ? item?.branch : levelIndex === 5 ? item?.activity : filterKeyValue,
                }
            });

            setDropdownData((prev: any) =>
                prev.map((dropdown: any, idx: number) => {
                    if (idx === levelIndex) {
                        return { ...dropdown, options: formattedOptions || [] };
                    }
                    //    else if (idx === levelIndex + 1) {
                    //     return { ...dropdown, isEnabled: true };
                    //   }
                    return dropdown;
                })
            );
        } catch (error) {
            console.error("Error fetching dropdown options:", error);
        }
    };


    const handleDropdownChange = (value: string, index: number) => {
        setDropdownData((prev: any) => {
            const updatedDropdownData = prev.map((dropdown: any, idx: number) => {
                if (idx === index) {
                    handleLevelChange(value, index, recordIndex, dropdown );
                    // Update the current dropdown value
                    return { ...dropdown, value };
                } else if (idx === index + 1) {
                    // Enable the next dropdown
                    return { ...dropdown, isEnabled: true, value: "", options: [] };
                } else if (idx > index + 1) {
                    // Reset all subsequent dropdowns
                    return { ...dropdown, isEnabled: false, value: "", options: [] };
                }
                return dropdown;
            });

            // Save the updated dropdownData to Redux
            dispatch(setLevelDropdownData({value: updatedDropdownData, index: recordIndex}));

            return updatedDropdownData;
        });

        // Fetch options for the next dropdown
        if (index < levels.length - 1) {
            const filterKey = levels[index + 1]?.filterKey;
            fetchOptions(index + 1, filterKey, value);
        }
    };

    const removeHandler = (index:number) => {
        removeISICHandler(index);
        setIsVisible(false);
    }

    useEffect(() => {
        fetchOptions(0);
    }, [])
    return (
        <>
            {!open ? (<div className="mapping-row mt-2 cursor-pointer" onClick={onToggle}>
                <div className="d-flex justify-content-center align-items-center">
                    <span className="sf-bold">{`#${recordIndex + 1}`}</span>
                </div>
                <div className="isic-job-heading-div d-flex flex-column justify-content-center align-items-start">
                    <span className="mx-3 no-of-jobs">{t("Number of Jobs in this ISIC Mapping")}</span>
                    <span className="mx-3 job-count">{noOfJobs?.value}</span>
                </div>
                <div className="d-flex justify-content-end align-items-center" style={{marginRight:'15px'}}>
                    <CButton className="border-btn">Modify</CButton>
                </div>
            </div>) : (<div className="isis-mapping-detail mt-3">
                <div className="mapping-detail-row">
                    <div className="d-flex justify-content-center align-items-center mapping-no">
                        <span className="sf-bold">{`#${recordIndex + 1}`}</span>
                    </div>
                    <div className="isic-job-heading-div d-flex flex-column justify-content-around align-items-start w-100">
                        <span className="mx-3 no-of-jobs">{t("Number of Jobs in this ISIC Mapping")}</span>
                        <CInput
                            placeholder={noOfJobs?.placeHolder}
                            className="mx-3 enter-job-field"
                            type={noOfJobs?.dataType}
                            value={noOfJobs?.value}
                            onChange={(e) => handleInputChange(e.target.value, recordIndex)}
                        />
                    </div>
                    <div className="d-flex justify-content-end align-items-center">
                        {totalLength !== 1 && (<CButton className="back-btn h-50 mx-2 w-25" onClick={() => handleDiscardRecord(recordIndex)}>{t("Discard")}</CButton>)}
                        <CButton className="next-btn h-50 w-25" onClick={onToggle}>{t("Done")}</CButton>
                    </div>
                </div>
                <div className="activity-level-main mt-2" style={{ height: '65px' }}>
                    <div className="d-flex justify-content-center align-items-center mapping-level">
                        <span className="click-drag sf-bold">{t("Activity Levels")}</span>
                    </div>
                    <div className="mapping-level d-flex flex-column justify-content-center align-items-start w-100">
                        <span className="mx-3 click-drag sf-bold">{t("Sector of Impact")}</span>
                        <span className="mx-3 level-sector-description">{t("Economic activity in which the sustainable impact of the project is achieved (long-term job creation impact)")}</span>
                    </div>
                </div>

                {levels?.map((item: any, index: number) => (
                    <div key={index} className="activity-level-main mt-2">
                        <div
                            className="d-flex justify-content-center align-items-center mapping-level level-count"
                            style={{ backgroundColor: "#F8FAFC" }}
                        >
                            <span className="click-drag sf-bold">{item.description}</span>
                        </div>
                        <div className="w-100">
                            {item.dataType === "dropdown" && (
                                <CustomSelect
                                    key={index}
                                    className="form-input h-100"
                                    options={ dropdownData[index].options}
                                    disabled={!dropdownData[index].isEnabled}
                                    {...(dropdownData[index].value !== '' && { value: dropdownData[index].value })} 
                                    onChange={(value) => handleDropdownChange(value, index)}
                                    placeholder={item.placeHolder}
                                    showSearch={true}
                                />
                            )}
                        </div>
                    </div>
                ))}

            </div>)}

            <AlertModal
                visible={isVisible}
                onCancel={() => setIsVisible(false)}
                onConfirm={() => removeHandler(indexNumber)}
                message="Are you Sure! You want to discard the Record"
            />
        </>
    );
};

export default ISICMappingCard;
