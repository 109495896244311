import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import languageReducer from './slices/languageSlice';
import dataSubmissionReducer from './slices/dataSubmissionSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    language: languageReducer,
    submission: dataSubmissionReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
